const inputStylesDark = {
  MuiTextField: {
    variants: [
      {
        props: props =>
          props.variant === "filled" && props.color === "predetermined",
        style: {
          "& .MuiInputBase-input": {
            color: "#ffffff",
          },
          "background": "rgb(9, 25, 40)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(17, 59, 92, 1)",
          },
          "&.Mui-focused": {
            background: "rgba(69, 131, 186, 1)",
          },
          // AUTOCOMPLETE //
          "& .MuiInputBase-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px rgba(17, 59, 92, 1) inset",
            color: "#ffffff ",
          },

          "& .MuiInputLabel-root:-webkit-autofill": {
            color: "#E3E3E3",
          },
        },
      },
      {
        props: props => props.variant === "filled" && props.color === "primary",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgb(9, 25, 40)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(17, 59, 92, 1)",
          },
          "&.Mui-focused": {
            background: "rgba(69, 131, 186, 1)",
          },
          "&.Mui-disabled": {
            backgroundColor: "#273542",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "secondary",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(8, 22, 35, 1)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#0C2134",
          },
          "&.Mui-focused": {
            background: "#0C2134",
          },
        },
      },
      {
        props: props => props.color === "tertiaryOnly",
        style: {
          "background": "rgba(8, 22, 35, 1)",
          "borderRadius": "5px",
          "border": "1px solid rgba(255, 255, 255, 1)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: " rgba(8, 22, 35, 1)",
          },
          "&.Mui-focused": {
            background: "background: rgba(8, 22, 35, 1)",
          },
        },
      },
    ],
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        "background-color": "transparent",
        "borderRadius": "5px",
        "&::before": {
          "background-color": "transparent",
          "borderBottom": " 2px solid rgba(255, 255, 255, 1)",
          "borderRadius": "5px",
        },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(17, 59, 92, 1)",
          "borderRadius": "5px",
        },
        "&.Mui-disabled:before": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(166, 165, 165, 1)",
          "border-radius": "5px",
        },
        "&.Mui-focused:after": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(69, 131, 186, 1)",
          "borderRadius": "5px",
        },
        "& .MuiInputBase-input": {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 90px 100px 100px rgba(17, 59, 92, 1) inset",
            Border: "2px solid gb(9, 25, 40)",
            WebkitTextFillColor: "#fff",
            caretColor: "#fff",
            color: "#fff",
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          color: "#ffff",
        },
      },
    },
  },

  // DATEPICKER
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "height": "48px",
        "border": 0,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #FFFFFF",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #FFFFFF",
        },
      },
    },
  },
};

const inputStylesLight = {
  MuiTextField: {
    variants: [
      {
        props: props =>
          props.variant === "filled" && props.color === "predetermined",
        style: {
          "& .MuiInputBase-input": {
            color: "#ffffff",
          },
          "& .MuiInputLabel-root": {
            color: "#E3E3E3",
          },
          "background": "rgb(9, 25, 40)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(17, 59, 92, 1)",
          },
          "&.Mui-focused": {
            background: "rgba(69, 131, 186, 1)",
          },
          // AUTOCOMPLETE //
          "& .MuiInputBase-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px rgba(17, 59, 92, 1) inset",
            color: "#ffffff ",
          },

          "& .MuiInputLabel-root:-webkit-autofill": {
            color: "#E3E3E3",
          },
        },
      },
      {
        props: props => props.variant === "filled" && props.color === "primary",
        style: {
          "& .MuiInputBase-input": {
            color: "#000",
          },
          "background": "rgb(230, 234, 239)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(199, 221, 240, 0.5)",
            border: "1px solid rgba(0, 0, 0, 0.3)",
          },
          "&.Mui-focused": {
            background: "rgb(230, 234, 239)",
          },
          "&.Mui-disabled": {
            backgroundColor: "#caced2",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "secondary",
        style: {
          "color": "#000",
          "background": "#F6F9FE",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#ECF0F5",
          },
          "&.Mui-focused": {
            background: "#ECF0F5",
          },
        },
      },
      {
        props: props => props.color === "tertiaryOnly",
        style: {
          "color": "#000",
          "background": "rgba(246, 249, 254, 1)",
          "borderRadius": "5px",
          "border": "1px solid rgba(18, 59, 100, 1)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(246, 249, 254, 1)",
          },
          "&.Mui-focused": {
            background: "rgba(246, 249, 254, 1)",
          },
        },
      },
    ],
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        "background-color": "transparent",
        "borderRadius": "5px",
        "&::before": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(18, 59, 100, 1)",
          "borderRadius": "5px",
        },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(33, 84, 131, 0.5)",
          "borderRadius": "5px",
        },

        "&.Mui-disabled:before": {
          "background-color": "transparent",
          "borderBottom": "2px solid rgba(33, 84, 131, 0.5)",
          "border-radius": "5px",
        },
        "&.Mui-focused:after": {
          "background-color": "transparent",
          "border-bottom": "2px solid rgba(56, 133, 191, 1)",
          "borderRadius": "5px",
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          color: "#396E9C",
        },
      },
    },
  },

  // DATEPICKER
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "height": "48px",
        "border": 0,
        "padding": "24px 14px 10px 14px ",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #123B64",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #123B64",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderBottom: "2px solid #123B64",
        },
      },
    },
  },
};

export { inputStylesDark, inputStylesLight };
