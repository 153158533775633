import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
// External components
import { Box, Card, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
import { GET_ALL_COINS } from "../../graphql";

function ResumeCard({ type }) {
  const { data: getCoinsData } = useQuery(GET_ALL_COINS);
  const [open, setOpen] = useState(false);
  const { lineDetail, useCoinDetail } = useElectronic();
  const { currentSymbolCoin, toExchangeRate, toLocalExchangeRate } =
    useCoinDetail;

  const colonCoin = getCoinsData?.coins.find(coin => coin.id === "1");

  const fixedList = useMemo(
    () =>
      (type === ElectronicBillTypes.CreditNote ||
        type === ElectronicBillTypes.DebitNote) &&
      Array.isArray(lineDetail)
        ? lineDetail
        : [],
    [type, lineDetail],
  );
  const getSubTotal = () => {
    const dollarsSubTotal = Array.isArray(fixedList)
      ? fixedList.reduce((acc, item) => acc + parseFloat(item.total), 0.0)
      : 0;

    return {
      dollars: dollarsSubTotal.toFixed(3),
      exchanged: toExchangeRate(dollarsSubTotal).toFixed(3),
    };
  };

  // I don't understand what is going on here, so i won't touch it
  const getDiscounts = () => {
    let total = 0.0;

    if (Array.isArray(fixedList)) {
      fixedList.forEach(item => {
        item.discountAmount?.forEach(discount => {
          total += parseFloat(discount || 0);
        });
      });
    }

    return {
      dollars: total.toFixed(3),
      exchanged: toExchangeRate(total).toFixed(3),
    };
  };

  const getOtherTaxes = () => {
    const otherDollarTaxes = Array.isArray(fixedList)
      ? fixedList?.reduce((acc, item) => acc + (item?.totalTaxes || 0), 0)
      : 0;

    return {
      dollars: otherDollarTaxes.toFixed(3),
      exchanged: toExchangeRate(otherDollarTaxes).toFixed(3),
    };
  };

  const getTotalTaxed = items =>
    items.reduce((acc, item) => acc + (item?.Taxed || 0), 0);

  const getTotalTariff = items =>
    items.reduce(
      (acc, item) =>
        acc + ((item?.Tariff?.Percent || 0) * (item?.total || 0)) / 100,
      0,
    );

  const getIVA = () => {
    const totalTaxed = Array.isArray(fixedList) ? getTotalTaxed(fixedList) : 0;
    const totalTariff = Array.isArray(fixedList)
      ? getTotalTariff(fixedList)
      : 0;

    const total = totalTaxed || totalTariff;

    return {
      dollars: total.toFixed(3),
      exchanged: toExchangeRate(total).toFixed(3),
    };
  };

  const getTotal = () => {
    const dollarsTotal =
      parseFloat(getSubTotal().dollars) -
      parseFloat(getDiscounts().dollars) +
      parseFloat(getOtherTaxes().dollars) +
      parseFloat(getIVA().dollars);

    return {
      dollars: dollarsTotal.toFixed(3),
      exchanged: toExchangeRate(dollarsTotal).toFixed(3),
    };
  };

  return (
    <Card
      color="primary"
      sx={{
        minHeight: "184px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      <div className="content-flex-row-between">
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Resumen
        </Typography>
        {type !== ElectronicBillTypes.CreditNote && (
          <FontAwesomeIcon
            className="icon-component"
            icon="info-circle"
            size="lg"
            onClick={() => setOpen(!open)}
          />
        )}
      </div>
      <div className="content-flex-row-between">
        <div>
          <p>Subtotal</p>
          <p className="background-discounts">Descuento</p>
          {getIVA().exchanged > 0 && (
            <p className="background-discounts">IVA</p>
          )}
          {getOtherTaxes().exchanged > 0 && (
            <p className="background-discounts">Otros Imp.</p>
          )}
        </div>
        <div className="container-summary">
          <p>
            {currentSymbolCoin}
            {getSubTotal().exchanged}
          </p>
          <p className="background-totals">
            {currentSymbolCoin}
            {getDiscounts().exchanged}
          </p>
          {getIVA().exchanged > 0 && (
            <p className="background-totals">
              {currentSymbolCoin}
              {getIVA().exchanged}
            </p>
          )}
          {getOtherTaxes().exchanged > 0 && (
            <p className="background-totals">
              {currentSymbolCoin}
              {getOtherTaxes().exchanged}
            </p>
          )}
        </div>
      </div>
      <Divider color="primary" />
      <div>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <p>Total</p>
          <p>
            {currentSymbolCoin}
            {getTotal().exchanged}
          </p>
        </Box>
        {colonCoin && currentSymbolCoin !== colonCoin.Symbol_Coin && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <p>Total en Colones</p>
            <p>
              {colonCoin?.Symbol_Coin}
              {toLocalExchangeRate(getTotal().dollars, colonCoin)}
            </p>
          </Box>
        )}
      </div>
    </Card>
  );
}

ResumeCard.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ResumeCard;
