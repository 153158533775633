import { gql } from "@apollo/client";
/* eslint-disable import/prefer-default-export */
export const GET_DEBIT_NOTES = gql`
  query GetDebitNotes($whereParams: WhereParams) {
    getDebitNotes(WhereParams: $whereParams) {
      id
      FK_Place
      Key
      Created_At
      Updated_At
      TotalGoodsExonerated
      TotalExempt
      TotalExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalNetSell
      TotalSell
      TotalServicesExempt
      TotalServicesExonerated
      TotalServicesTaxed
      TotalTaxed
      TotalTaxes
      TotalBill
      Coin {
        Buy_Coin
        Code_Coin
        Name_Coin
        Sell_Coin
        Symbol_Coin
        id
        Updated_At
        Created_At
      }
      FK_Coin
      ActivityCode
      EmitedDay

      InternConsecutive
      Exchange
      User {
        Person {
          Name_Person
          Lastname_Person
          id
        }
      }
      FK_User
      FK_ElectronicBillDocumentType
      ElectronicBillDocumentType {
        Description
      }
      ReferenceCode
      ReferenceReason
      FK_AcceptanceState
      BillFlowType
      FK_OwnerPlace
      FK_ReferencedElectronicBill
      AcceptanceDate
      FK_PaymentElectronicDocument
      FK_DocumentAcceptance
      BaseTax
      Factor
      PlatformExchange
      DebitNoteDetails {
        Quantity
        FK_ProductFeature
        Price
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            UserBillingProfile {
              Name
              ComercialName
              Email
              ID_Number
              PhoneNumber
              OtherSigns
              MH_Province {
                Name
              }
              MH_Canton {
                Name
              }
              MH_District {
                Name
              }
              MH_Neighborhood {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DEBIT_NOTES_CARD = gql`
  query GetDebitNotes($whereParams: WhereParams) {
    getDebitNotes(WhereParams: $whereParams) {
      Key
      Coin {
        Name_Coin
        Symbol_Coin
        Sell_Coin
      }
      Key
      EmitedDay
      TotalSell
    }
  }
`;
