/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const REGISTER_NEW_PLACE = gql`
  mutation createdPlace($place: PlaceInput, $address: AddressInput) {
    place: createPlace(place: $place, address: $address) {
      id
    }
  }
`;
export const REGISTER_NEW_PLACE_AND_USER_ROLE = gql`
  mutation createdPlace(
    $place: PlaceInput
    $address: AddressInput
    $FK_User: ID
    $Origin: String
  ) {
    place: createPlaceAndUserRole(
      place: $place
      address: $address
      FK_User: $FK_User
      Origin: $Origin
    ) {
      id
      IsUsingDefaultBillingProfile
    }
  }
`;

export const UPDATE_PLACE = gql`
  mutation updatedPlace($place: PlaceInput, $address: AddressInput) {
    place: updatePlace(place: $place, address: $address) {
      id
      Name_Place
      Code
      Description_Place
      State_Place
      IsUsingDefaultBillingProfile
      Files {
        id
        Route_File
      }
      Company {
        id
      }
      Address {
        id
      }
      Gmail
      Gmail_App
      BillingProfile {
        id
        MH_User {
          idUser
          FileEB {
            idFile
            name
          }
        }
      }
      DefaultBillingProfile {
        id
        MH_User {
          idUser
          FileEB {
            idFile
            name
          }
        }
      }
    }
  }
`;
