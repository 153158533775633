/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_INVENTORIES_BY_PLACE = gql`
  query GetByPlace($FK_Place: ID!) {
    inventories: getInventoriesByPlace(FK_Place: $FK_Place) {
      id
      Description
    }
  }
`;

// Example usage:
// client.query({
//   query: GET_INVENTORIES_BY_PLACE,
//   variables: { FK_Place: "PLACE_ID_HERE" }, // Replace "PLACE_ID_HERE" with the actual ID
// });
