import { useApolloClient } from "@apollo/client";
import { useElectronic } from "../context/ElectronicContext";
import { UserErrors } from "../Enums/Errors";
import { CREATE_CREDIT_NOTE, GET_EB_FOR_NOTES } from "../graphql";
import { client_EB_api } from "../graphql/client";
import { formatElectronicBillDetailsFormattedWithMaxQuantity } from "../utils/electronicBill";

const validResponse = { message: "", data: "", hasError: false };
const useCreditNote = () => {
  const client = useApolloClient();
  const {
    lineDetail,
    LineDetailPlace,
    electronicBill,
    setElectronicBill,
    setLineDetail,
    setLineDetailPlace,
    setReceiver,
    useCoinDetail,
  } = useElectronic();

  const getEBWithNotes = async id => {
    const {
      data: { bill },
    } = await client.query({
      query: GET_EB_FOR_NOTES,
      variables: {
        id,
      },
    });
    return bill;
  };

  const getValidElectronicDocument = async id => {
    const data = await getEBWithNotes(id);
    if (!data) return null;
    if (!data?.ElectronicBillDetail.length === 0) return null;

    return formatElectronicBillDetailsFormattedWithMaxQuantity(data);
  };

  const saveCreditNote = async noteInfo => {
    const selectedProducts = lineDetail.map(product => ({
      Quantity: product.quantity,
      FK_ProductFeature: product.id,
      tax: product.Tariff.Percent,
      Price: parseFloat(product.unit_price),
    }));

    const dataToSend = {
      docEB: {
        featureIds: [...selectedProducts],
        referenceDocumentId: electronicBill.id,
        code: noteInfo.code,
        reason: noteInfo.reason,
        activityCode: LineDetailPlace.code,
      },
    };
    return client_EB_api.mutate({
      mutation: CREATE_CREDIT_NOTE,
      variables: {
        ...dataToSend,
      },
    });
  };
  const loadCreditNote = async id => {
    const data = await getValidElectronicDocument(id);
    if (!data) return;

    const products = data?.ElectronicBillDetail?.map(index => {
      const feature = data?.ReferencedDocument?.Bill?.Detail?.find(
        detail => detail?.ProductFeature?.id === index?.ProductFeature?.id,
      );
      if (feature) {
        return {
          ...index,
          Quantity_BillDetail: feature?.Quantity_BillDetail,
          Total_BillDetail: feature?.Total_BillDetail,
        };
      }
      return null;
    }).filter(index => index?.id);

    const result =
      products?.length &&
      products?.map(item => {
        const {
          ProductFeature: {
            Details,
            Product,
            ProductPrice,
            Files,
            id: productFeature_id,
          },
        } = item;
        return {
          detail_id: item.id,
          id: productFeature_id,
          description: Product?.Description_Product,
          cabys: ProductPrice?.Cabys,
          Tariff: ProductPrice?.Tariff,
          unit_price: ProductPrice?.Price_ProductPrice,
          quantity: item.Quantity,
          discount: [0],
          tax: ProductPrice?.Tax || 0,
          taxes: [],
          totalTaxes: 0,
          total: item?.Total_BillDetail || 0,
          unit_Measure: "",
          product_Code: "",
          url: Files,
          details: Details,
          value: item.id,
          label: Product?.Description_Product,
          valid: item.valid,
          maxQuantity: item.maxQuantity,
          checked: true,
        };
      });

    const place =
      data?.ElectronicBillDetail[0]?.ProductFeature?.InventoryDetail?.Inventory
        ?.Cellar.Place;
    setLineDetailPlace({
      id: place?.id,
      Name_Place: place?.Name_Place,
      Company: { id: place?.Company.id },
      IsUsingDefaultBillingProfile: place?.IsUsingDefaultBillingProfile,
      BillingProfile: {
        id: place?.BillingProfile?.id,
        MH_User: {
          FileEB: { name: place?.BillingProfile?.MH_User?.FileEB.name },
        },
      },
      DefaultBillingProfile: {
        id: place?.DefaultBillingProfile?.id,
        MH_User: {
          FileEB: { name: place?.DefaultBillingProfile?.MH_User?.FileEB.name },
        },
      },
    });
    data?.ReferencedDocument?.Receiver?.id &&
      setReceiver({
        id: data?.ReferencedDocument?.Receiver?.id,
        Receiver_Id:
          data?.ReferencedDocument?.Receiver?.UserBillingProfile?.ID_Number,
        Receiver_Email:
          data?.ReferencedDocument?.Receiver?.UserBillingProfile?.Email,
        Receiver_Name:
          data?.ReferencedDocument?.Receiver?.UserBillingProfile?.Name,
        Receiver_PhoneNumber:
          data?.ReferencedDocument?.Receiver?.UserBillingProfile?.PhoneNumber,
      });
    setLineDetail(result);
    setElectronicBill({
      id: data.id,
      Sell_Condition: data.SellCondition,
      Coin: data.Coin.id,
      Payment_Method: data.PaymentMethod,
      OtherPaymentMethod: data.Payment_Detail,
      OtherSellCondition: data.SellCondition_Detail,
      Key: data.Key,
    });
    useCoinDetail.handleCoinChange(data.Coin.id);
  };

  const atLeastOneChecked = () => ({
    hasError: lineDetail.length === 0,
    message: UserErrors.EMPTY_CHECKED_PRODUCTS,
  });

  const saveCreditNoteValidations = [atLeastOneChecked];

  const canSaveCN = () => {
    const validationFailed = saveCreditNoteValidations
      .map(val => val())
      .find(val => val.hasError);
    return validationFailed || { ...validResponse };
  };

  return {
    getValidElectronicBill: getValidElectronicDocument,
    saveCreditNote,
    loadCreditNote,
    canSaveCN,
  };
};

export default useCreditNote;
