import React from "react";
import PropTypes from "prop-types";
// External components
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// SCSS
import "../CreditDebitNotesPage.scss";

function SelectType({ data, onChange, option, label, labelId, width }) {
  return (
    <FormControl sx={{ width }} variant="filled">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        color="secondary"
        label="Tipo de documentos"
        labelId={labelId}
        name="is_accepted"
        size="small"
        value={option}
        variant="filled"
        onChange={e => onChange(e.target.value)}
      >
        {data?.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectType.propTypes = {
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  option: PropTypes.string.isRequired,
  width: PropTypes.number,
};

SelectType.defaultProps = {
  width: 333, // default width value
};

export default SelectType;
