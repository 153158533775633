import { Card } from "@mui/material";
import { React, useState } from "react";
import PropTypes from "prop-types";
// Graphql
import { client_EB_api } from "../../graphql/client";
import { GET_ALL_REFERENCE_TYPE } from "../../graphql";
// SCSS
import "./NotesForm.scss";
import InputForm from "../InputForm/InputForm";
import SelectForm from "../SelectForm/SelectForm";
import { formatData } from "../../utils/helpers";

function NotesForm({ reason_name, code_name, disabled, noteInfo, onChange }) {
  const [referencesList, setReferencesList] = useState([]);

  const handleLoad = async event => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_REFERENCE_TYPE,
    });
    setReferencesList(data.allReferenceType);
  };

  const handleChange = (variable_name, value) => {
    onChange(e => ({ ...e, [variable_name]: value }));
  };

  return (
    <Card
      color="primary"
      sx={{
        height: "210px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <h2>Detalles de la Nota</h2>

      <div className="content-flex-column">
        <SelectForm
          color="secondary"
          data={formatData(referencesList, "Document_Type")}
          disabled={disabled}
          label="Seleccionar el codìgo"
          labelId="demo-simple-select-filled-label"
          name={code_name}
          size="small"
          value={noteInfo[code_name]}
          onChange={e => handleChange(e.target.name, e.target.value)}
          onFocus={e => handleLoad(e)}
        />
        <InputForm
          color="secondary"
          disabled={disabled}
          id="reason"
          label="Razón"
          name={reason_name}
          size="small"
          value={noteInfo[reason_name]}
          variant="filled"
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
      </div>
    </Card>
  );
}

NotesForm.propTypes = {
  reason_name: PropTypes.string.isRequired,
  code_name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  noteInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
NotesForm.defaultProps = {
  disabled: false,
};

export default NotesForm;
