import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Button } from "@mui/material";
// Utils
import { decrypt, encrypt, customToast as toast } from "../../../../utils";
import InputForm from "../../../InputForm/InputForm";
import { client_EB_api } from "../../../../graphql/client";
import { UPDATE_PROFILE_EB } from "../../../../graphql";
import SimpleGrid from "../../../SimpleGrid";

function MHUser({ billingProfile, disabled, onChange }) {
  const {
    id,
    Name,
    Email,
    MH_PersonType: { Name_PersonType } = {},
    MH_User,
  } = billingProfile || {};

  const [showPassword, setShowPassword] = useState(false);

  const [userMH, setUserMH] = useState({
    idUser: MH_User?.idUser,
    MH_User: MH_User?.MH_User,
    MH_Password: MH_User?.MH_Password && decrypt(MH_User?.MH_Password),
    fullName: Name,
    email: Email,
    about: Name_PersonType,
    country: "52",
    FK_Profile: id,
  });

  useEffect(() => {
    if (!billingProfile) return;

    setUserMH({
      idUser: billingProfile?.MH_User?.idUser,
      MH_User: billingProfile?.MH_User?.MH_User,
      MH_Password: billingProfile?.MH_User?.MH_Password
        ? decrypt(billingProfile.MH_User.MH_Password)
        : "",
      fullName: billingProfile?.Name || "",
      email: billingProfile?.Email || "",
      about: billingProfile?.MH_PersonType?.Name_PersonType || "",
      country: "52",
      FK_Profile: billingProfile?.id || null,
    });
  }, [billingProfile]);

  const hadleOnChange = e => {
    setUserMH({ ...userMH, [e.target.name]: e.target.value });
  };

  const saveUserData = async event => {
    event.preventDefault();
    if (disabled) {
      toast.warning("Acción Inválida");
      return;
    }
    try {
      const MH_Password = encrypt(userMH.MH_Password);
      const {
        data: { BillingProfile },
      } = await client_EB_api.mutate({
        mutation: UPDATE_PROFILE_EB,
        variables: { userMH: { ...userMH, MH_Password } },
      });
      toast.success("Credenciales de hacienda actualizadas");
      onChange(BillingProfile);
    } catch (e) {
      toast.error("Error al actualizar los credenciales de hacienda");
    }
  };

  return (
    <Box className="content-flex-column" sx={{ paddingY: "1rem" }}>
      <SimpleGrid>
        <InputForm
          color="primary"
          disabled={disabled}
          id="email"
          label="Usuario"
          name="MH_User"
          size="small"
          type="email"
          value={userMH.MH_User}
          onChange={hadleOnChange}
        />
        <InputForm
          color="primary"
          disabled={disabled}
          id="MH_Password"
          label="Contraseña"
          name="MH_Password"
          showPassword={showPassword}
          size="small"
          type={showPassword ? "text" : "password"}
          typeInput="password"
          value={userMH.MH_Password}
          onChange={hadleOnChange}
          onClickPassword={() => setShowPassword(!showPassword)}
        />
        <Button
          color="primary"
          disabled={disabled}
          sx={{
            height: 50,
            maxWidth: "137px",
          }}
          type="submit"
          variant="contained"
          onClick={saveUserData}
        >
          Reemplazar
        </Button>
      </SimpleGrid>
    </Box>
  );
}

MHUser.propTypes = {
  onChange: PropTypes.func,
  billingProfile: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

MHUser.defaultProps = {
  onChange: userEB => null,
  disabled: false,
};
export default MHUser;
