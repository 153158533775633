import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { toast } from "react-toastify";
import { Box, Grid } from "@mui/material";
// Components
import {
  DataStack,
  ExportationProductDataDisplayer,
  TitledCard,
} from "./components";
import {
  ClientCard,
  PaymentDataCard,
  RegisterReceiver,
} from "../../components";
import FormWithButtons from "../../components/FormWithButttons/FormWithButtons";
import Loading from "../../components/Loading";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useModal } from "../../context";
// Hook
import useExportationBill from "../../hooks/useExportationBill";
import useUsers from "../../hooks/useUsers";
// GraphQL
import { client_EB_api } from "../../graphql/client";
import { GET_PLACE } from "../../graphql";
// Utils
import { customToast } from "../../utils";
import {
  getPlaceBillingProfileToUse,
  placeHasValidBillingProfile,
} from "../../utils/placeUtils";
import { billingProfileHasValidMHData } from "../../utils/billingProfileUtils";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
import { PROVIDER_GROUP } from "../../Enums/General";
// Scss
import "./ExportationBillPage.scss";

function ExportationBillPage() {
  const {
    receiver,
    useCoinDetail,
    lineDetail,
    setLineDetail,
    resetReceiver,
    isBilling,
    setIsBilling,
    resetExportationBasicInfo,
    LineDetailPlace,
    setAddress,
    setClientGroup,
    setReceiver,
  } = useElectronic();
  const { handleCreatDraftFEE, handleCreateFEE } = useExportationBill();
  const navigate = useNavigate();
  const { setModalOpen } = useModal();
  const { addNewClient, dataClientsGroups, createGroup, refetchGroups } =
    useUsers();
  const { resetDefaultCoin } = useCoinDetail;
  const [emitterData, setEmitterData] = useState({
    isLoadingData: true,
    auxArray: [
      {
        label: "Nombre",
        value: "",
      },
      { label: "Cédula", value: "" },
      { label: "E-mail", value: "" },
      { label: "Teléfono", value: "" },
    ],
    secondSegment: [
      { label: "Provincia", value: "" },
      { label: "Canton", value: "" },
      { label: "Distrito", value: "" },
      { label: "Otras señas", value: "" },
    ],
  });

  async function getAndSaveEmitterData() {
    setEmitterData(prev => ({ ...prev, isLoadingData: true }));
    const { data, error } = await client_EB_api.query({
      query: GET_PLACE,
      variables: {
        where: {
          id: parseFloat(LineDetailPlace.id),
        },
      },
    });

    if (error || !data || !data.place) {
      customToast.error("No se pudo obtener información sobre el emisor.");
      return;
    }

    const billingProfileData = getPlaceBillingProfileToUse(data.place);

    setEmitterData({
      isLoadingData: false,
      auxArray: [
        {
          label: "Nombre",
          value: billingProfileData.Name,
        },
        { label: "Cédula", value: billingProfileData.ID_Number },
        { label: "E-mail", value: billingProfileData.Email },
        { label: "Teléfono", value: billingProfileData.PhoneNumber },
      ],
      secondSegment: [
        { label: "Provincia", value: billingProfileData.MH_Province.Name },
        { label: "Canton", value: billingProfileData.MH_Canton.Name },
        { label: "Distrito", value: billingProfileData.MH_District.Name },
        { label: "Otras señas", value: billingProfileData.OtherSigns },
      ],
    });
  }

  const validateExportationElectronicBill = () => {
    if (lineDetail.length === 0) return false;
    return true;
  };

  const createDraftExportationElectronicBill = async () => {
    const savedDraftBill = await handleCreatDraftFEE();

    if (!savedDraftBill?.data?.createDraftFEE) {
      toast.error('Ocurrió un error al generar la factura."');
    }

    return savedDraftBill;
  };

  const createExportationElectronicBill = async () => {
    if (!placeHasValidBillingProfile(LineDetailPlace)) {
      customToast.error(
        "Perfil de facturación inválido. Verifíquelo antes de volverlo a intentar",
      );
      return;
    }
    if (
      !billingProfileHasValidMHData(
        getPlaceBillingProfileToUse(LineDetailPlace),
      )
    ) {
      customToast.error(
        "Datos de Hacienda inválidos. Verifíquelos antes de volverlo a intentar",
      );
      return;
    }
    const responseDraft = await createDraftExportationElectronicBill();
    if (!responseDraft?.data?.createDraftFEE?.id) return;
    const responseFEE = await handleCreateFEE(
      responseDraft?.data?.createDraftFEE?.id,
    );

    if (
      !responseFEE?.data?.createExportationBill ||
      responseFEE?.data?.createExportationBill?.error
    ) {
      toast.error(
        "Ocurrió un error al guardar la factura electrónica de exportación",
      );
      return;
    }
    toast.success(
      "Se ha guardado exitosamente la factura electrónica de exportación",
    );
    navigate(
      `/ElectronicBillView/${responseFEE.data.createExportationBill.id}/created`,
    );
  };

  const addNewClientAddapter = async profile => {
    const providersGroup = dataClientsGroups?.groups?.find(
      ({ Name_ClientGroup }) => Name_ClientGroup === PROVIDER_GROUP,
    );
    const selectedGroup =
      providersGroup?.id ||
      (
        await createGroup({
          Name_ClientGroup: PROVIDER_GROUP,
          FK_Place: LineDetailPlace.id,
        })
      )?.clientGroup?.id;
    refetchGroups();
    if (!selectedGroup) {
      return customToast.error("Ocurrió un error al crear el grupo");
    }
    const newClient = await addNewClient(profile, selectedGroup, "Proveedor");
    const client = newClient?.data?.client;
    const billingProfile = {
      id: client?.BillingProfile?.id,
      Receiver_Id: client?.BillingProfile?.ID_Number,
      Receiver_Name: client?.BillingProfile?.Name,
      Receiver_Email: client?.BillingProfile?.Email,
      Receiver_PhoneCode: client?.BillingProfile?.PhoneCodCountry,
      Receiver_PhoneNumber: client?.BillingProfile?.PhoneNumber,
      Receiver_PersonType: client?.BillingProfile?.MH_PersonType,
      user: { id: client?.BillingProfile?.User.id },
    };
    const address = {
      Province:
        client?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province,
      Canton: client?.BillingProfile?.MH_Neighborhood?.District?.Canton,
      District: client?.BillingProfile?.MH_Neighborhood?.District,
      Neighborhood: client?.BillingProfile?.MH_Neighborhood,
      Other: client?.BillingProfile?.OtherSigns,
    };

    setAddress(address);
    setClientGroup({ id: 0 });
    setModalOpen(false);
    return setReceiver(billingProfile);
  };

  const handleOpenModal = () => {
    setModalOpen(
      true,
      <Box
        className="modal-content-size container-modal "
        sx={{
          height: { xs: "600px", sm: "100%" },
          width: { xs: "333px", sm: "100%" },
        }}
      >
        <RegisterReceiver
          newClient
          FK_User={0}
          handleChangeAddress={() => null}
          handleCreateNewClient={addNewClientAddapter}
          handleOnClosed={() => setModalOpen(false)}
          initialReceiver={null}
          setBillingProfile={null}
          title="Perfil de facturación"
        />
      </Box>,
    );
  };

  useEffect(() => {
    if (!isBilling) {
      resetReceiver();
      resetDefaultCoin();
      resetExportationBasicInfo();
      setLineDetail([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  useEffect(() => {
    getAndSaveEmitterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitledCard title="Datos del Emisor">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              width: "100%",
              justifyContent: emitterData.isLoadingData
                ? "center"
                : "space-between",
              alignItems: emitterData.isLoadingData ? "center" : undefined,
            }}
          >
            {emitterData.isLoadingData ? (
              <Loading />
            ) : (
              <React.Fragment>
                <DataStack data={emitterData.auxArray} />
                <DataStack data={emitterData.secondSegment} />
              </React.Fragment>
            )}
          </Box>
        </TitledCard>
      </Grid>
      <Grid item md={6} xs={12}>
        <ClientCard
          data={{
            Id: receiver?.Receiver_Id,
            Name: receiver?.Receiver_Name,
            Email: receiver?.Receiver_Email,
            PhoneNumber: receiver?.Receiver_PhoneNumber,
          }}
          optional={
            receiver.Receiver_Id === ""
              ? "Click para buscar (opcional)"
              : ` ${receiver?.Receiver_Name}, 
                                ${receiver?.Receiver_Id}`
          }
          optional2={
            receiver?.Receiver_Email === "" &&
            receiver?.Receiver_PhoneNumber === ""
              ? ""
              : ` ${receiver?.Receiver_Email}, 
                              ${receiver?.Receiver_PhoneNumber}`
          }
          sx={{
            height: "100%",
          }}
          type={ElectronicBillTypes.FacturaElectronica}
          onClickSubmit={() => handleOpenModal()}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PaymentDataCard
          sx={{
            height: "100%",
          }}
          type={ElectronicBillTypes.FacturaElectronica}
        />
      </Grid>
      <Grid item xs={12}>
        <ExportationProductDataDisplayer />
      </Grid>
      <Grid item xs={12}>
        <FormWithButtons
          buttonsAlignment="end"
          disableSubmit={!validateExportationElectronicBill()}
          submitText="Generar Factura"
          onSubmit={createExportationElectronicBill}
        />
      </Grid>
    </Grid>
  );
}

export default ExportationBillPage;
