import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_EB_BY_CLIENTNAME_AND_DATE } from "../../graphql";
// Components
import { EBResumeCard } from "../../components";
import Loading from "../../components/Loading";
import SelectType from "./SelectType";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./CreditDebitNotesPage.scss";
// Helpers
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
// Icon
import searchIcon from "../../assets/icons/search_icon.svg";

function CreditNotePage() {
  const [filter, setFilter] = useState({
    name: "",
    date: null,
  });

  const documentOptions = [
    "Todos",
    "Facturas Electrónicas",
    "Tiquetes Electrónicos",
  ];
  const [documentTypeFilter, setDocumentTypeFilter] = useState("Todos");

  const navigate = useNavigate();
  const { LineDetailPlace } = useElectronic();

  const [getElectronicBills, { data: ElectronicBills, loading }] = useLazyQuery(
    GET_EB_BY_CLIENTNAME_AND_DATE,
  );

  const handleGetEB = useCallback(() => {
    getElectronicBills({
      variables: {
        eBSearchFormWithClient: {
          place_id: LineDetailPlace.id,
          date: filter?.date || new Date(),
          ClientName: filter.name,
        },
      },
    });
  }, [getElectronicBills, filter, LineDetailPlace.id]);

  const handleChange = (name, value) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  };

  registerLocale("Es", Es);
  useEffect(() => {
    handleGetEB();
  }, [filter, handleGetEB]);

  const creditNotesSortedAscendant = sortArrayItemsByDate(
    ElectronicBills?.getEDByUserNameAndDate,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );

  const filterByType = () => {
    if (documentTypeFilter === "Todos") return creditNotesSortedAscendant;
    if (documentTypeFilter === "Facturas Electrónicas") {
      return creditNotesSortedAscendant.filter(
        index => index?.ElectronicBillDocumentType?.id === "1",
      );
    }
    return creditNotesSortedAscendant.filter(
      index => index?.ElectronicBillDocumentType?.id !== "1",
    );
  };
  return (
    <Box className="content-flex-column">
      <div className="items-notes">
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  alt="Buscar"
                  className="search-icon"
                  src={searchIcon}
                  style={{ width: "20px", height: "20px" }}
                />
              </InputAdornment>
            ),
          }}
          color="primary"
          name="name"
          placeholder="Buscar por nombre"
          size="small"
          sx={{ width: "30%" }}
          value={filter.name}
          variant="filled"
          onChange={({ target: { name, value } }) => handleChange(name, value)}
        />
        <DatePicker
          color="primary"
          label="Mes y año de emisión"
          size="small"
          sx={{
            "& .MuiFormLabel-root": {
              transform: " translate(14px, 5px) scale(0.75)",
            },
          }}
          value={filter?.date}
          variant="filled"
          views={["month", "year"]}
          onChange={date => setFilter(prev => ({ ...prev, date }))}
        />
        <SelectType
          color="secondary"
          data={documentOptions}
          label="Tipo de documentos"
          option={documentTypeFilter}
          onChange={setDocumentTypeFilter}
        />
      </div>

      {loading && <Loading />}
      {ElectronicBills?.getEDByUserNameAndDate?.length > 0 ? (
        <div className="content-flex-cards">
          {filterByType(creditNotesSortedAscendant).map(item => (
            <EBResumeCard
              key={item.id}
              actions={[
                {
                  text: "Ver notas",
                  onClick: () => navigate(`/CreditDebitNote/${item.id}/Notes`),
                },
              ]}
              item={item}
            />
          ))}
        </div>
      ) : (
        !loading && (
          <Typography align="left" typography="modeColor" variant="subtitle1">
            ¡No se han encontrado registros!
          </Typography>
        )
      )}
    </Box>
  );
}

export default CreditNotePage;
