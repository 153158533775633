import React, { useState, useEffect, useRef, useCallback } from "react";
// External components
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  RadioGroup,
  Typography,
} from "@mui/material";
// Graphql
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_PLACE,
  GET_ACTIVITIES_BY_ID_MH,
  GET_PLACE_BY_ID,
  GET_PLACE,
  GET_ALL_PLACES_BY_USER,
} from "../../graphql";
import { client_EB_api } from "../../graphql/client";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import Loading from "../Loading";
import SquareRadioCheck from "../SquareRadioButton/SquareRadioButton";
import RegisterReceiver from "../RegisterReceiver";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import InputForm from "../InputForm/InputForm";
import MHData from "../MHData";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import {
  customToast as toast,
  decrypt,
  encrypt,
  validateEmail,
  validateStringLength,
  validateFormObject,
} from "../../utils";
import { validateID } from "../../utils/idDocument";
import { validateRoute } from "../../utils/helpers";
// SCSS
import "./PlacesUpdate.scss";
import "../../pages/ProfilePage/ProfilePage.scss";

function PlacesUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [isFetchingBillingProfileData, setIsFetchingBillingProfileData] =
    useState(false);
  const [placeForm, setPlaceForm] = useState({
    id: "",
    Code: "",
    Name_Place: "",
    Description_Place: "",
    FK_Company: "",
    State_Place: "",
    Gmail: "",
    Gmail_App: "",
    IsUsingDefaultBillingProfile: true,
    BillingProfile: undefined,
    DefaultBillingProfile: undefined,
  });
  const [auxBillingProfile, setAuxBillingProfile] = useState({});
  const [selectActivityCode, setSelectActivityCode] = useState({
    id_MH: "",
    Name: "",
  });

  const { LineDetailPlace, saveOnStorage, patchBillingProfile } =
    useElectronic();
  const {
    permissions,
    state: {
      user: { TavuelUser },
    },
  } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const canEdit = haveActionPermission("Edit", "/places", LineDetailPlace);
  const { setModalOpen } = useModal();

  const canvas = useRef(null);

  const client = useApolloClient();
  const { loading, data: getActivityData } = useQuery(GET_ACTIVITIES_BY_ID_MH, {
    variables: {
      id_MH: placeForm?.Code,
    },
  });
  const { data: placeData, loading: isFetchingPlaceData } = useQuery(
    GET_PLACE_BY_ID,
    {
      variables: { id },
    },
  );
  const [updatePlace, { loading: isUpdatingPlace }] = useMutation(
    UPDATE_PLACE,
    {
      update: (cache, { data: { place } }) => {
        const queryResult = cache.readQuery({
          query: GET_ALL_PLACES_BY_USER,
        });

        if (!queryResult) return;

        const updatedPlaces = queryResult.places.map(p =>
          p.id === place.id ? { ...p, ...place } : p,
        );

        cache.writeQuery({
          query: GET_ALL_PLACES_BY_USER,
          data: {
            places: {
              updatedPlaces,
            },
          },
        });
      },
      onCompleted: data => {
        if (LineDetailPlace.id === data?.place.id) saveOnStorage(data?.place);
      },
    },
  );

  const getAndStorePlaceBillingProfiles = async placeId => {
    setIsFetchingBillingProfileData(true);
    const {
      data: { place },
      error,
    } = await client_EB_api.query({
      query: GET_PLACE,
      variables: {
        where: {
          id: parseFloat(placeId),
        },
      },
      fetchPolicy: "no-cache",
    });
    setIsFetchingBillingProfileData(false);
    if (error || !place) {
      toast.error("Error al obtener información de facturación");
      return;
    }

    const { BillingProfile, DefaultBillingProfile } = place;

    setPlaceForm(prev => ({
      ...prev,
      BillingProfile,
      DefaultBillingProfile,
    }));
    setAuxBillingProfile(BillingProfile);
  };

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(placeForm);
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlaceForm(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event, newValue) => {
    if (!newValue) return;
    setPlaceForm(prev => ({ ...prev, Code: newValue.value }));
  };

  const defaultValue = selectActivityCode
    ? {
        value: selectActivityCode.id_MH,
        label: selectActivityCode.Name,
      }
    : null;

  const updatePlaceFullProcess = useCallback(
    async ({ place, address, billingProfile }) => {
      const Origin = process.env.REACT_APP_NAME_CONTA;
      if (!Origin)
        return {
          place: undefined,
          error: "No se pudo encontrar un origen",
        };

      if (!billingProfile && !placeForm.IsUsingDefaultBillingProfile)
        return {
          place: undefined,
          error: "No se puede guardar sin un perfil de facturación válido.",
        };

      if (billingProfile) {
        const resp = await patchBillingProfile(billingProfile);
        if (!resp)
          return {
            place: undefined,
            error: "Error al guardar el perfil de facturación",
          };
      }

      const { data } = await updatePlace({
        variables: {
          place,
          address,
        },
      });

      if (!data)
        return {
          place: undefined,
          error: "No se obtuvo respuesta del servidor.",
        };

      const { place: placeReponse } = data;

      if (!placeReponse)
        return {
          place: undefined,
          error: "Error al actualizar el lugar.",
        };

      return { place: placeReponse, error: undefined };
    },
    [patchBillingProfile, placeForm.IsUsingDefaultBillingProfile, updatePlace],
  );

  const update = useCallback(async () => {
    try {
      const billingProfileValidationConfig = {
        fieldLabels: {
          Name: "Nombre",
          Lastname: "Apellido",
          MH_PersonType: "Tipo de Persona",
          ID_Number: "Número de Identificación",
          Email: "Correo Electrónico",
          PhoneNumber: "Número de Teléfono",
          PhoneCodCountry: "Código de País (Teléfono)",
          MH_Province: "Provincia",
          MH_Canton: "Cantón",
          MH_District: "Distrito",
          MH_Neighborhood: "Barrio",
          OtherSigns: "Otras Señas",
          Tax_Registration: "Registro Fiscal8707",
        },
        customValidations: {
          Email: [
            {
              validate: validateEmail,
              message: "Correo electrónico no válido",
            },
          ],
          Name: [
            {
              validate: validateStringLength({ minLength: 3 }),
              message: "El nombre debe tener al menos 3 caracteres",
            },
          ],
          PhoneNumber: [
            {
              validate: validateStringLength({ minLength: 8 }),
              message: "El número de teléfono debe tener al menos 8 caracteres",
            },
          ],
          ID_Number: [
            {
              validate: idValue =>
                !validateID({
                  id: idValue,
                  idType: placeForm.BillingProfile.MH_PersonType,
                }),
              message: idValue =>
                validateID({
                  id: idValue,
                  idType: placeForm.BillingProfile.MH_PersonType,
                }),
            },
          ],
        },
        exceptions: [
          "id",
          "MH_User",
          "ComercialName",
          "IDForeign",
          "OtherSignsForeign",
        ],
      };

      if (!placeForm.IsUsingDefaultBillingProfile) {
        const validationRespone = validateFormObject(
          placeForm.BillingProfile,
          billingProfileValidationConfig,
        );

        if (!validationRespone.isValid) {
          toast.error(validationRespone.message);
          return;
        }
      }

      const address = {
        id: null,
      };
      const {
        BillingProfile,
        DefaultBillingProfile,
        Gmail_App,
        ...placeFormData
      } = placeForm;

      const billingProfileValue =
        !BillingProfile || placeFormData.IsUsingDefaultBillingProfile
          ? undefined
          : {
              ...BillingProfile,
              id: BillingProfile.id,
              Name: `${BillingProfile.Name} ${BillingProfile.Lastname}`,
              FK_User: undefined,
              Lastname: undefined,
              FK_Place: placeFormData.id,
            };

      const { error } = await updatePlaceFullProcess({
        place: {
          ...placeFormData,
          Gmail_App: encrypt(Gmail_App),
        },
        address,
        billingProfile: billingProfileValue,
      });

      if (error) {
        toast.error(error);
        return;
      }
      client.refetchQueries({ include: ["getPlaces"] });

      toast.success("¡Lugar actualizado con éxito!");
    } catch (err) {
      toast.error("Algo ha salido mal.");
    }
  }, [client, placeForm, updatePlaceFullProcess]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!canEdit) {
      toast.error(noAccessMessage("Edit", "Places"));
      return;
    }
    if (placeForm.Name_Place !== "" && placeForm.Description_Place !== "") {
      update();
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlaceForm(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (placeData) {
      const {
        Code,
        Name_Place,
        Description_Place,
        Company,
        State_Place,
        Gmail,
        Gmail_App,
        IsUsingDefaultBillingProfile,
      } = placeData.place;

      const lastFile = placeData.place.Files.at(-1);

      setPlaceForm({
        id,
        Code,
        Name_Place,
        Description_Place,
        FK_Company: Company?.id,
        State_Place,
        Gmail,
        Gmail_App: Gmail_App ? decrypt(Gmail_App) : Gmail_App,
        IsUsingDefaultBillingProfile,
        Files: lastFile
          ? [
              {
                id: lastFile.id,
                Route_File: lastFile.Route_File,
                FirebaseId: lastFile.FirebaseId,
                FileType: { id: lastFile.FileType?.id },
              },
            ]
          : undefined,
      });
      getAndStorePlaceBillingProfiles(id);
    }
    setSelectActivityCode({
      id_MH: getActivityData?.activity?.id_MH,
      Name: getActivityData?.activity?.Name,
    });
  }, [placeData, getActivityData, id]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Typography
          fontWeight={600}
          marginBottom={5}
          textAlign="center"
          typography="modeColor"
          variant="h6"
        >
          Actualización de Lugares
        </Typography>
        {loading && !placeForm?.id ? (
          <div>
            <Loading />
          </div>
        ) : (
          <Grid container margin={0}>
            <Grid item textAlign="center" xs={12}>
              <Button
                component="label"
                sx={{ aspectRatio: "square", width: 111, listStyle: "none" }}
              >
                <Input hidden type="file" onChange={hadleOnSelectFile} />
                <Box
                  component="img"
                  src={validateImgRoute()}
                  sx={{ objectFit: "cover" }}
                />
              </Button>
            </Grid>

            <Grid item paddingTop={5} xs={12}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    label="Compañía"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={placeData?.place?.Company?.Name_Company}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {selectActivityCode ? (
                    <SelectActivityCode
                      defaultValue={defaultValue}
                      handleChange={handleSelectChange}
                    />
                  ) : (
                    <div>Cargando...</div>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Name_Place"
                    label="Nombre"
                    name="Name_Place"
                    placeholder="Nombre"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={placeForm?.Name_Place}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Gmail"
                    label="Correo Gmail"
                    name="Gmail"
                    placeholder="Correo Gmail"
                    size="small"
                    type="email"
                    typeInput="email"
                    value={placeForm.Gmail}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Description_Place"
                    label="Descripción"
                    name="Description_Place"
                    placeholder="Descripción"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={placeForm?.Description_Place}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Gmail_App"
                    label="Contraseña de aplicación Gmail"
                    name="Gmail_App"
                    placeholder="Contraseña de aplicación Gmail"
                    size="small"
                    type={isPasswordVisible ? "text" : "password"}
                    typeInput="password"
                    value={placeForm.Gmail_App}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    onClickPassword={() => setIsPasswordVisible(prev => !prev)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item paddingTop={5} xs={12}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: { xs: 2, sm: 2, md: 6, lg: 12 },
                  }}
                  value={
                    placeForm.IsUsingDefaultBillingProfile ? "default" : "own"
                  }
                  onChange={({ target: { value } }) =>
                    handleChange(
                      "IsUsingDefaultBillingProfile",
                      value === "default",
                    )
                  }
                >
                  <FormControlLabel
                    control={<SquareRadioCheck />}
                    label="Utilizar perfil de facturación de usuario"
                    value="default"
                  />
                  <FormControlLabel
                    control={<SquareRadioCheck />}
                    label="Utilizar perfil de facturación nuevo"
                    value="own"
                  />
                </RadioGroup>
              </FormControl>

              <Box
                sx={{
                  display: placeForm.IsUsingDefaultBillingProfile
                    ? "block"
                    : "none",
                }}
              >
                <RegisterReceiver
                  disabled
                  hideFormButtons
                  isAdmin
                  FK_User={TavuelUser?.id}
                  initialReceiver={placeForm.DefaultBillingProfile}
                  title="Perfil de facturación"
                />
              </Box>
              <Box
                sx={{
                  display: placeForm.IsUsingDefaultBillingProfile
                    ? "none"
                    : "block",
                }}
              >
                <RegisterReceiver
                  hideFormButtons
                  isAdmin
                  FK_User={TavuelUser?.id}
                  initialReceiver={auxBillingProfile}
                  title="Perfil de facturación"
                  onBillingProfileChanged={BillingProfile =>
                    handleChange("BillingProfile", {
                      ...placeForm.BillingProfile,
                      ...BillingProfile,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item paddingTop={5} xs={12}>
              <MHData
                billingProfile={
                  placeForm.IsUsingDefaultBillingProfile
                    ? placeForm.DefaultBillingProfile
                    : placeForm?.BillingProfile || {}
                }
                disabled={
                  placeForm.IsUsingDefaultBillingProfile ||
                  !placeForm.BillingProfile?.id
                }
                setFileEB={FileEB =>
                  setPlaceForm(prev => ({
                    ...prev,
                    BillingProfile: {
                      ...prev.BillingProfile,
                      MH_User: { ...prev.BillingProfile.MH_User, FileEB },
                    },
                  }))
                }
                setMHUser={MH_User =>
                  setPlaceForm(prev => ({
                    ...prev,
                    BillingProfile: { ...prev.BillingProfile, MH_User },
                  }))
                }
                setModalOpen={setModalOpen}
              />
            </Grid>
            <Grid item paddingTop={5} xs={12}>
              <FormWithButtons
                buttonsAlignment="end"
                buttonsSpace={3}
                disableSubmit={
                  isUpdatingPlace ||
                  isFetchingBillingProfileData ||
                  isFetchingPlaceData
                }
                onCancel={() => navigate("/places")}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </form>
  );
}

export default PlacesUpdate;
