import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Select,
} from "@mui/material";
import { v4 as uuid } from "uuid";
// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import {
  REGISTER_USER_AND_SEND_EMAIL,
  UPDATE_USER_ROLE,
  GET_ROLES_BY_PLACE,
  REGISTER_USER_TO_PLACE_WITH_EXISTS_ACCOUNT,
  DELETE_USER_ROLE_BY_PLACE_AND_ROLE,
} from "../../../../graphql";
// Components
import SelectCountry from "../../../CountrySelect";
// Context
import { useElectronic } from "../../../../context/ElectronicContext";
import { useAuth } from "../../../../context";
// Utils
import { customToast, encrypt } from "../../../../utils";
// SCSS
import "./AddNewUser.scss";
import InputForm from "../../../InputForm/InputForm";
import FormWithButtons from "../../../FormWithButttons/FormWithButtons";
import { getdate } from "../../../../utils/helpers";
import { PhoneInput } from "../../../Common";

function AddNewUser({
  setCloseModal,
  title,
  refetch,
  componentMode,
  editUser,
}) {
  const isEditMode = componentMode === "edit";
  const isAddUserWithAccount = componentMode === "UserExists";
  const { countries } = useElectronic();
  const [userRole, setUserRole] = useState("0");
  const [stateUserReg, setstateUserReg] = useState({
    Given_Name: "",
    Email: "",
    Password: encrypt(uuid().substring(0, 8)),
    Family_Name: "",
    BirthDate_Person: "",
    Phone_Number: "",
    PhoneCodCountry: "506",
    FK_Coin: "1",
    FK_Country: "52",
    AcceptTerms: true,
  });

  const { permissions } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const { isSuperAdmin } = permissions;

  // queries
  const { data: rolesByPlaceData } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  // mutation
  const [registerUserAndSendEmail] = useMutation(REGISTER_USER_AND_SEND_EMAIL);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [deleteUserRoleByPlaceAndRole] = useMutation(
    DELETE_USER_ROLE_BY_PLACE_AND_ROLE,
  );

  const [registerUserToPlaceWithExistsAccount] = useMutation(
    REGISTER_USER_TO_PLACE_WITH_EXISTS_ACCOUNT,
  );

  useEffect(() => {
    if (isEditMode) {
      setstateUserReg({
        Given_Name: editUser?.FirebaseUser?.Name,
        Email: editUser?.FirebaseUser?.Email,
      });
      setUserRole(editUser.Roles[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    if (isAddUserWithAccount) {
      setstateUserReg({
        Given_Name: editUser.Name,
        Email: editUser.Email,
      });
      setUserRole("0");
    }
  }, [editUser, isAddUserWithAccount]);

  const handleChangeRegister = (name, value) => {
    setstateUserReg(prev => ({ ...prev, [name]: value }));
  };

  const handleCancel = useCallback(() => {
    setCloseModal(false);
  }, [setCloseModal]);

  const handleRegisterUser = useCallback(async () => {
    try {
      const {
        data: { registeredUser },
      } = await registerUserAndSendEmail({
        variables: {
          user: {
            ...stateUserReg,
            Locale: window.navigator.userLanguage || window.navigator.language,
            BirthDate_Person: new Date(stateUserReg.BirthDate_Person),
          },
          FK_Role: userRole,
        },
      });
      if (registeredUser) {
        customToast.success("¡Usuario registrado con éxito!");
        handleCancel();
        refetch();
      } else {
        customToast.error("Error al registrar el usuario");
      }
    } catch (err) {
      customToast.error(err.message);
    }
  }, [registerUserAndSendEmail, stateUserReg, userRole, handleCancel, refetch]);

  const handleUpdateRoleUser = useCallback(async () => {
    const NewInfo = {
      FK_User: editUser.id,
      FK_Role: userRole,
      FK_GeneralState: 1,
    };
    const idUserRole = editUser?.UserRoles?.find(e => e.FK_Role);
    try {
      const { data } = await updateUserRole({
        variables: {
          id: idUserRole.id,
          NewInfo,
        },
      });
      if (data) {
        customToast.success("¡Rol de Usuario actualizado con éxito!");
        handleCancel();
        refetch();
      } else {
        customToast.error("Error al actualizar rol de usuario");
      }
    } catch (err) {
      customToast.error("Error al actualizar rol de usuario");
    }
  }, [
    editUser.id,
    editUser?.UserRoles,
    userRole,
    updateUserRole,
    handleCancel,
    refetch,
  ]);

  const handleDeleteRoleUser = useCallback(async () => {
    try {
      const { data } = await deleteUserRoleByPlaceAndRole({
        variables: {
          user: editUser.id,
          role: userRole,
        },
      });
      if (data) {
        customToast.success("¡Rol de Usuario eliminado con éxito!");
        handleCancel();
        refetch();
      } else {
        customToast.error("Error al eliminar rol de usuario");
      }
    } catch (err) {
      customToast.error("Error al eliminar rol de usuario");
    }
  }, [
    editUser.id,
    deleteUserRoleByPlaceAndRole,
    userRole,
    handleCancel,
    refetch,
  ]);

  const handleSaveAndUser = async e => {
    if (
      stateUserReg.Given_Name === "" ||
      stateUserReg.Email === "" ||
      stateUserReg.Family_Name === "" ||
      stateUserReg.BirthDate_Person === "" ||
      stateUserReg.Phone_Number === "" ||
      userRole === 0
    ) {
      customToast.error("Debe completar los datos solicitados.");
    } else if (!isEditMode) {
      await handleRegisterUser();
      refetch();
    } else {
      refetch();
    }
  };

  const handleUpdateUser = async e => {
    if (userRole === 0) {
      customToast.error("Debe completar los datos solicitados.");
    } else {
      await handleUpdateRoleUser();
      refetch();
    }
  };

  const handleDeleteUserRole = async e => {
    e.preventDefault();
    await handleDeleteRoleUser();
    refetch();
  };

  const showSuperAdminRole = Name_Role => {
    if (Name_Role !== "Superadmin") {
      return true;
    }
    if (Name_Role === "Superadmin" && !isSuperAdmin) {
      return false;
    }
    return true;
  };

  const handleAddUserExists = async e => {
    if (userRole === 0) {
      customToast.error("Debe completar los datos solicitados.");
      return;
    }
    const { data } = await registerUserToPlaceWithExistsAccount({
      variables: {
        FK_Role: userRole,
        FK_User: editUser.User.id,
      },
    });
    refetch();
    if (data) {
      customToast.success("¡Usuario registrado con éxito!");
      handleCancel();
    }
  };

  const handleSubmit = () => {
    if (isAddUserWithAccount) {
      handleAddUserExists();
    } else if (isEditMode) {
      handleUpdateUser();
    } else {
      handleSaveAndUser();
    }
  };

  let submitText;
  if (isAddUserWithAccount) {
    submitText = "Guardar";
  } else if (isEditMode) {
    submitText = "Actualizar";
  } else {
    submitText = "Guardar";
  }

  return (
    <Box className="container-modal">
      <Box
        className="modal-content-padding"
        sx={{
          overflow: "auto",
          maxHeight: "600px",
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          {title}
        </Typography>
        {isEditMode || isAddUserWithAccount ? (
          <Typography noWrap textAlign="start" typography="modeColor">
            <b>Correo electrónico: </b>
            {stateUserReg.Email}
          </Typography>
        ) : (
          <InputForm
            className={
              (isEditMode || isAddUserWithAccount) && "no-border-bottom"
            }
            color="primary"
            disabled={isEditMode || isAddUserWithAccount}
            id="Email"
            label="Email"
            name="Email"
            size="small"
            type="text"
            value={stateUserReg.Email}
            variant="filled"
            onChange={e => handleChangeRegister(e.target.name, e.target.value)}
          />
        )}
        {isEditMode || isAddUserWithAccount ? (
          <Typography noWrap textAlign="start" typography="modeColor">
            <b>Nombre del usuario(a): </b>
            {stateUserReg.Given_Name}
          </Typography>
        ) : (
          <InputForm
            className={
              (isEditMode || isAddUserWithAccount) && "no-border-bottom"
            }
            color="primary"
            disabled={isEditMode || isAddUserWithAccount}
            id="Given_Name"
            label="Nombre del usuario(a)"
            name="Given_Name"
            size="small"
            type="text"
            value={stateUserReg.Given_Name}
            variant="filled"
            onChange={e => handleChangeRegister(e.target.name, e.target.value)}
          />
        )}

        {!isEditMode && !isAddUserWithAccount && (
          <div className="content-flex-column">
            <InputForm
              className={
                (isEditMode || isAddUserWithAccount) && "no-border-bottom"
              }
              color="primary"
              disabled={isEditMode}
              id="Family_Name"
              label="Apellidos del usuario(a)"
              name="Family_Name"
              size="small"
              type="text"
              value={stateUserReg.Family_Name}
              variant="filled"
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
            <SelectCountry
              disabled={isEditMode}
              name="FK_Country"
              textColor="black"
              value={stateUserReg.FK_Country}
              onChange={handleChangeRegister}
            />
            <PhoneInput
              code={stateUserReg.PhoneCodCountry}
              codeKey="PhoneCodCountry"
              countries={countries}
              phone={stateUserReg.Phone_Number}
              phoneKey="Phone_Number"
              placeholder="Número telefonico:"
              onChange={(name, value) => {
                handleChangeRegister(name, value);
              }}
            />
            <InputForm
              className={
                (isEditMode || isAddUserWithAccount) && "no-border-bottom"
              }
              color="primary"
              disabled={isEditMode}
              id="BirthDate_Person"
              label="Fecha de nacimiento"
              name="BirthDate_Person"
              size="small"
              type="date"
              value={
                stateUserReg.BirthDate_Person.length === 0
                  ? getdate()
                  : stateUserReg.BirthDate_Person
              }
              variant="filled"
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </div>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <FormControl fullWidth sx={{ minWidth: 120 }} variant="filled">
            <InputLabel id="Role">Seleccione un rol</InputLabel>
            <Select
              color="secondary"
              label="Seleccione un rol"
              labelId="Role"
              name="Role"
              size="small"
              value={userRole}
              variant="filled"
              onChange={e => setUserRole(e.target.value)}
            >
              {rolesByPlaceData?.getRolesByPlace.map(
                role =>
                  showSuperAdminRole(role.Name_Role) && (
                    <MenuItem key={role.id} value={role.id}>
                      {role.Name_Role}
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
          {userRole !== "0" && isEditMode && (
            <Button
              color="secondary"
              sx={{ height: "48px", width: "13rem" }}
              variant="contained"
              onClick={handleDeleteUserRole}
            >
              Eliminar Rol
            </Button>
          )}
        </Box>
        <FormWithButtons
          buttonsAlignment="end"
          cancelText="Cancelar"
          submitText={submitText}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
}

AddNewUser.propTypes = {
  setCloseModal: PropTypes.func,
  title: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  componentMode: PropTypes.string.isRequired,
  editUser: PropTypes.object,
};

AddNewUser.defaultProps = {
  setCloseModal: e => e,
  editUser: {},
};

export default AddNewUser;
