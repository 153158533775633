/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
// External components
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import * as converter from "xml-js";
import dayjs from "dayjs";
// Components
import IncomesDropper from "./components/IncomesDropper";
import IncomeCard from "./components/IncomeCard";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// Utils
import { customToast as toast, chunk } from "../../utils";
import { castElectronicBillToIncomeXml } from "../../utils/electronicBill";
// Enums
import { ELECTRONIC_BILL_XML_TYPES } from "../../Enums/ElectronicBill";
// SCSS
import "./IncomePage.scss";
import "../ProfilePage/ProfilePage.scss";
import { GET_INCOMES } from "../../graphql";

function IncomePage() {
  const [xmlIncomes, setXmlIncomes] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [showLoadMoreAlert, setShowLoadMoreAlert] = useState(
    incomes.length > 0,
  );
  const [allSelected, setAllSelected] = useState(true);

  const { state, permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const navigate = useNavigate();
  const { LineDetailPlace } = useElectronic();
  const { handleElectronicBillsXML } = useElectronicBill();
  const canRead = haveActionPermission("Read", "/incomes", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Ingresos"));
    navigate(`/`);
  }
  const limitSelectValues = ["10", "20", "30", "40", "50"];
  const [filterData, setFilterData] = useState({
    minFilterDate: dayjs().subtract(5, "days"),
    maxFilterDate: dayjs(),
    ebAmount: limitSelectValues[0],
  });
  const filterDataRef = useRef(filterData);
  const incomesDataRef = useRef(incomes);
  const lineDetailPlaceRef = useRef(LineDetailPlace);
  const [getIncomes, { loading: loadingIncomes }] = useLazyQuery(GET_INCOMES);

  const handleSelectAllClick = () => {
    // evento del botón "Seleccionar todas"
    // Selecciona todas las facturas en el estado invoices
    setXmlIncomes(prev =>
      prev.map(invoice => ({ ...invoice, selected: !allSelected })),
    );
    setAllSelected(prev => !prev);
  };

  const onXmlIncomeCheckChanged = (key, value) => {
    setXmlIncomes(prev =>
      prev.map(invoice =>
        invoice.Clave._text === key ? { ...invoice, selected: value } : invoice,
      ),
    );
  };

  const handleCleanBills = () => {
    setXmlIncomes([]);
  };

  const removeDuplicatedXMLByKey = xmls =>
    xmls.reduce(
      (acc, xml) => {
        if (!acc.dataKeys.includes(xml?.Clave?._text)) {
          acc.data.push(xml);
          acc.dataKeys.push(xml?.Clave?._text);
        }
        return acc;
      },
      { data: [], dataKeys: [] },
    );

  const isEBType = EBType =>
    !!Object.values(ELECTRONIC_BILL_XML_TYPES).find(type => type === EBType);

  const normalizeUploadedDocument = uploadedDocument => {
    const documentType = Object.keys(uploadedDocument).find(isEBType);
    return { ...uploadedDocument[documentType], documentType };
  };

  const xmlFilesToJson = async files => {
    const data = await Promise.allSettled(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target: { result } }) => {
              try {
                const bill = normalizeUploadedDocument(
                  JSON.parse(
                    converter.xml2json(result, { compact: true, spaces: 4 }),
                  ),
                );
                resolve(bill);
              } catch (e) {
                reject(e);
              }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
          }),
      ),
    );

    const succses = await data
      .filter(({ status }) => status === "fulfilled")
      .map(({ value }) => value);

    const failed = data.filter(({ status }) => status === "rejected").length;
    failed && toast.error(`No se lograron abrir ${failed} documentos`);

    return succses;
  };

  const validateOwner = dataJSON => {
    try {
      const { DocumentId_Person } = state.user.TavuelUser.Person;
      const DocumentId_Emisor = dataJSON?.Emisor?.Identificacion?.Numero;
      return DocumentId_Person === DocumentId_Emisor._text;
    } catch (err) {
      return false;
    }
  };

  const isAlreadyUploaded = useCallback(
    facturaJSON =>
      xmlIncomes
        .map(({ Clave: { _text } }) => _text)
        .includes(facturaJSON?.Clave?._text),
    [xmlIncomes],
  );

  const isValidXMLType = type => /text\/(xml)/.test(type);

  const readXml = async files => {
    const { data: uniqueXmls } = removeDuplicatedXMLByKey(
      await xmlFilesToJson(files),
    );

    uniqueXmls.forEach(facturaJSON => {
      if (!validateOwner(facturaJSON)) {
        toast.error(
          "Verifique que todas las facturas coincidan con su perfil de facturación",
        );
        return;
      }
      if (isAlreadyUploaded(facturaJSON)) {
        toast.error(
          `La factura ...${facturaJSON.Clave._text.slice(
            -11,
          )} ya fue subida anteriormente `,
        );
        return;
      }
      setXmlIncomes(prev => [...prev, { ...facturaJSON, selected: true }]);
    });
  };

  const handleSaveIncomeBill = async () => {
    if (LineDetailPlace.id === 0) {
      toast.error("No se ha seleccionado un Negocio");
      return;
    }
    const request = chunk(xmlIncomes, 5).map(async xChucnk =>
      handleElectronicBillsXML(
        state.user.TavuelUser,
        xChucnk,
        LineDetailPlace.id,
        2,
      ),
    );

    const messages = await Promise.all(request, { concurrency: 1 }).reduce(
      (finalMessages, current) => {
        const { response, message, success } = current;
        if (!response) {
          toast.success("Facturas guardadas con éxito");
          return finalMessages;
        }
        if (response === "errors") {
          finalMessages.errors.push(...message);
        }
        if (response === "error") {
          return Object.assign(finalMessages, { error: message });
        }
        if (success) {
          return Object.assign(finalMessages, { success: message });
        }
        return finalMessages;
      },
      { errors: [], sucess: undefined, error: null },
    );

    messages.errors.forEach(bill => {
      if (bill !== "") {
        toast.error(
          `La factura:
        ${bill},
        ya se encuentra registrada,
        favor revisar las facturas`,
        );
      }
    });
    messages.error && toast.error(messages.error);
  };

  const searchIncomes = async isSearchingMore => {
    const { data } = await getIncomes({
      variables: {
        IncomeInput: {
          FK_Place: lineDetailPlaceRef.current.id,
          minEmitedDay: filterDataRef.current.minFilterDate,
          maxEmitedDay: filterDataRef.current.maxFilterDate,
          limit: filterDataRef.current.ebAmount,
          offset: isSearchingMore ? incomesDataRef.current.length : undefined,
          orderBy: [{ columnName: "emitedDay", order: "DESC" }],
        },
      },
      fetchPolicy: "no-cache",
    });

    const newIncomesLoaded = data?.getIncomes || [];

    const formatedIncomes = newIncomesLoaded.map(income =>
      castElectronicBillToIncomeXml(income),
    );

    const incomesListValues = isSearchingMore
      ? [...incomesDataRef.current, ...formatedIncomes]
      : formatedIncomes;

    setShowLoadMoreAlert(newIncomesLoaded.length !== 0);

    setIncomes(incomesListValues);
    incomesDataRef.current = incomesListValues;
  };

  useEffect(() => {
    filterDataRef.current = filterData;
    lineDetailPlaceRef.current = LineDetailPlace;
  }, [LineDetailPlace, filterData]);

  useEffect(() => {
    let debounceTimeout = null;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        const windowHeight = window.innerHeight;
        const fullHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY;
        const isUserEndOfPage = scrollTop + windowHeight >= fullHeight - 5;

        if (loadingIncomes || !isUserEndOfPage) return;
        searchIncomes(true);
      }, 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        minHeight: "calc(100vh - 175px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "end",
            flexWrap: "wrap",
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={e => {
              e.preventDefault();
              window.open("/electronic-bills", "_blank");
            }}
          >
            Crear Ingreso
          </Button>

          {xmlIncomes.length !== 0 && (
            <React.Fragment>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleCleanBills}
              >
                Limpiar pantalla
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSelectAllClick}
              >
                {!allSelected ? "Seleccionar todas" : "Deseleccionar todas"}
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={handleSaveIncomeBill}
              >
                Guardar
              </Button>
            </React.Fragment>
          )}
        </Box>
        <Grid container spacing={{ xs: 1, lg: 2 }} width="100%">
          <Grid item md={3} sm={6} xs={12}>
            <DatePicker
              color="primary"
              defaultValue={filterData.minFilterDate}
              format="DD/MM/YYYY"
              label="Desde"
              maxDate={filterData.maxFilterDate}
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  transform: "translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "10px 14px 0px 14px",
                },
                "maxHeight": "48px",
                "width": "100%",
              }}
              value={filterData.minFilterDate}
              variant="filled"
              onChange={date =>
                setFilterData(prev => ({ ...prev, minFilterDate: date }))
              }
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <DatePicker
              color="primary"
              defaultValue={filterData.maxFilterDate}
              format="DD/MM/YYYY"
              label="Hasta"
              maxDate={dayjs()}
              minDate={filterData.minFilterDate}
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  transform: "translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "10px 14px 0px 14px",
                },
                "maxHeight": "48px",
                "width": "100%",
              }}
              value={filterData.maxFilterDate}
              variant="filled"
              onChange={date =>
                setFilterData(prev => ({ ...prev, maxFilterDate: date }))
              }
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth sx={{ minWidth: 120 }} variant="filled">
              <InputLabel>Cantidad de facturas</InputLabel>
              <Select
                color="secondary"
                defaultValue={limitSelectValues[0]}
                label="Cantidad de facturas"
                name="quantityEmails"
                size="small"
                value={filterData.ebAmount}
                variant="filled"
                onChange={({ target: { value } }) =>
                  setFilterData(prev => ({ ...prev, ebAmount: value }))
                }
              >
                {limitSelectValues.map(index => (
                  <MenuItem key={index} value={index}>
                    {index}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box>
              <Button
                color="secondary"
                disabled={loadingIncomes}
                sx={{ height: "48px", width: "100%" }}
                variant="contained"
                onClick={() => searchIncomes(false)}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        <Box sx={{ minWidth: { xs: 275, sm: 350 }, width: 350 }}>
          <IncomesDropper
            onFilesDrop={Files =>
              readXml(Files.filter(file => isValidXMLType(file.type)))
            }
          />
        </Box>
        {xmlIncomes.map(income => (
          <Box sx={{ minWidth: { xs: 275, sm: 350 }, width: 350 }}>
            <IncomeCard
              incomeData={income}
              onCardCheckedChange={onXmlIncomeCheckChanged}
            />
          </Box>
        ))}
      </Box>
      {xmlIncomes && <Divider flexItem />}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        {incomes.map(income => (
          <Box sx={{ minWidth: { xs: 275, sm: 350 }, width: 350 }}>
            <IncomeCard incomeData={income} />
          </Box>
        ))}
      </Box>
      {showLoadMoreAlert && (
        <Alert severity="info" variant="outlined">
          {loadingIncomes ? "Cargando..." : "¡Baja para cargar más!"}
        </Alert>
      )}
    </Box>
  );
}

export default IncomePage;
