import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function SelectForm({
  label,
  labelId,
  color,
  disabled,
  name,
  value,
  onChange,
  onFocus,
  data,
  size,
  menuOptionDefault,
  type,
}) {
  return (
    <FormControl fullWidth sx={{ minWidth: 60 }} variant="filled">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        color={color}
        disabled={disabled}
        label={label}
        labelId={labelId}
        name={name}
        size={size}
        value={value}
        variant="filled"
        onChange={onChange}
        onFocus={onFocus}
      >
        {data?.map(option =>
          type === "default" ? (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ) : (
            <MenuItem key={option.id} value={option.id}>
              +{option.phoneNumber} {option.name}
            </MenuItem>
          ),
        )}
        {data.length === 0 && (
          <MenuItem key="default" value="default">
            {menuOptionDefault}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

SelectForm.propTypes = {
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  data: PropTypes.array,
  size: PropTypes.string,
  menuOptionDefault: PropTypes.string,
  type: PropTypes.string,
};

SelectForm.defaultProps = {
  color: "primary",
  disabled: false,
  size: "",
  menuOptionDefault: "",
  data: [],
  onFocus: () => {},
  type: "default",
  labelId: "",
};
export default SelectForm;
