/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_PLACES_BY_USER = gql`
  query getPlaces($FK_User: ID!, $FK_Module: ID!) {
    places: getPlacesByUserAndModule(FK_User: $FK_User, FK_Module: $FK_Module) {
      id
      Name_Place
      Description_Place
      Code
      IsUsingDefaultBillingProfile
      Files {
        id
        Route_File
      }
      Company {
        id
        Name_Company
        Description_Company
        Files {
          id
          Route_File
        }
      }
      BillingProfile {
        id
        MH_User {
          idUser
          FileEB {
            idFile
            name
          }
        }
      }
      DefaultBillingProfile {
        id
        MH_User {
          idUser
          FileEB {
            idFile
            name
          }
        }
      }
      State_Place
      Gmail
      Gmail_App
    }
  }
`;
export const GET_PLACE_BY_ID = gql`
  query getPlace($id: String) {
    place: getPlace(id: $id) {
      id
      Name_Place
      Description_Place
      Code
      State_Place
      Gmail
      Gmail_App
      IsUsingDefaultBillingProfile
      Files {
        id
        FirebaseId
        Route_File
        FileType {
          id
        }
      }
      Company {
        id
        Name_Company
        Description_Company
        Files {
          id
          Route_File
        }
      }
    }
  }
`;
export const GET_PLACE_BY_NAME = gql`
  query placeByName($name: String) {
    place: getPlaceByName(name: $name) {
      id
      Name_Place
      Description_Place
      Code
      Files {
        id
        Route_File
      }
      Company {
        id
        Name_Company
        User {
          id
        }
      }
    }
  }
`;
export const GET_PLACE_BY_USER_ID = gql`
  query getPlaceByUserId($FK_User: ID) {
    place: placeByUserId(FK_User: $FK_User) {
      id
    }
  }
`;

export const GET_PLACE = gql`
  query getPlace($where: GetPlaceWhereInput!) {
    place: getPlace(where: $where) {
      id
      IsUsingDefaultBillingProfile
      BillingProfile {
        id
        id
        Name
        MH_PersonType {
          id
          Name_PersonType
          Code
        }
        ID_Number
        IDForeign
        ComercialName
        Email
        PhoneCodCountry
        PhoneNumber
        Tax_Registration
        MH_Province {
          id
          Name
          id_MH
        }
        MH_Canton {
          id
          Name
          id_MH
        }
        MH_District {
          id
          Name
          id_MH
        }
        MH_Neighborhood {
          id
          Name
          id_MH
        }
        MH_User {
          idUser
          fullName
          userName
          email
          pwd
          about
          country
          FileEB {
            idFile
            md5
            name
            timestamp
            size
            idUser
            fileType
            type
          }
          Session {
            idSession
            idUser
            sessionKey
            ip
            lastAccess
          }
          MH_User
          MH_Password
        }
        OtherSigns
        OtherSignsForeign
      }
      DefaultBillingProfile {
        id
        id
        Name
        MH_PersonType {
          id
          Name_PersonType
          Code
        }
        ID_Number
        IDForeign
        ComercialName
        Email
        PhoneCodCountry
        PhoneNumber
        Tax_Registration
        MH_Province {
          id
          Name
          id_MH
        }
        MH_Canton {
          id
          Name
          id_MH
        }
        MH_District {
          id
          Name
          id_MH
        }
        MH_Neighborhood {
          id
          Name
          id_MH
        }
        MH_User {
          idUser
          fullName
          userName
          email
          pwd
          about
          country
          FileEB {
            idFile
            md5
            name
            timestamp
            size
            idUser
            fileType
            type
          }
          Session {
            idSession
            idUser
            sessionKey
            ip
            lastAccess
          }
          MH_User
          MH_Password
        }
        OtherSigns
        OtherSignsForeign
      }
    }
  }
`;
