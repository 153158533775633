import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
// External components
import { Card, Typography } from "@mui/material";
import { toast } from "react-toastify";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./OptionCard.scss";

function OptionCard({
  title,
  icon,
  optional,
  mainComponent,
  disabled,
  img,
  hideTitle,
  sx,
}) {
  const navigate = useNavigate();

  const { setIsBilling } = useElectronic();

  const location = useLocation();

  const handleSetRoute = () => {
    if (location.pathname === "/buy-electronic-bills") {
      if (title === "Proveedor") {
        setIsBilling(true);
        navigate("/buy-electronic-bills/client");
      }
      if (title === "Tienda") {
        setIsBilling(true);
        navigate("/buy-electronic-bills/store");
      }
    }
    if (title === "Cliente") {
      navigate("/electronic-bills/client");
    } else if (title === "Inventarios") {
      setIsBilling(true);
      navigate("/electronic-bills/inventories");
    } else if (title === "Añadir productos") {
      setIsBilling(true);
      navigate("/electronic-bills/details");
    }
  };

  return (
    <Card
      color="primary"
      sx={{
        cursor: "pointer",
        height: "120px",
        display: "flex",
        flexDirection: "column",
        alignItems: icon ? "center" : "start",
        justifyContent: "center",
        padding: "1rem",
        width: "100%",
        ...sx,
      }}
      onClick={!disabled ? handleSetRoute : () => toast.info("desabilitado")}
    >
      {icon && <img alt="img" className="img_option icon-color" src={icon} />}
      {!mainComponent && (
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          {title}
        </Typography>
      )}
      {mainComponent && !hideTitle && (
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          {title}
        </Typography>
      )}
      <div className="content-flex-column">
        <div className="content-flex-row-between">
          {img && <img alt="img" className="img_option" src={img} />}
          <Typography
            align="center"
            typography="modeColor"
            variant="subtitle1"
            width="100%"
          >
            {optional}
          </Typography>
        </div>
      </div>
    </Card>
  );
}

OptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  optional: PropTypes.string,
  mainComponent: PropTypes.bool,
  disabled: PropTypes.bool,
  img: PropTypes.string,
  hideTitle: PropTypes.bool,
  sx: PropTypes.object,
};

OptionCard.defaultProps = {
  mainComponent: false,
  optional: "",
  disabled: false,
  img: "",
  hideTitle: false,
  sx: undefined,
};

export default OptionCard;
