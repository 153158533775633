/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { useDropzone } from "react-dropzone";
import {
  Card,
  Typography,
  Button,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import * as converter from "xml-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useZoho from "../../hooks/useEmail";
import useElectronicBill from "../../hooks/useElectronicBill";
// Components
import BooleanInput from "../BooleanInput";
import ProfileBillWrapper from "../ProfileBillWrapper";
import BillUploadDetail from "../BillUploadDetail";
import Inventories from "../Inventories";
import {
  ZohoSearch,
  AccountManagement,
  NewAccount,
  CardAccount,
} from "./components";
// Utils
import { formatCurrency, customToast as toast, chunk } from "../../utils";
import { arrayBill, formatDateString, toZohoMonths } from "../../utils/helpers";
import getImageEmail from "../../utils/GetImageEmail";
// Enum
import EMAIL_PROVIDERS from "../../Enums/EmailToken";
// SCSS
import "./ExpensesUpload.scss";
import fileIcon from "../../assets/icons/file_icon.svg";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

function renderAccountManagementInChild({ key, openModal }) {
  return <AccountManagement key={key} openModal={openModal} />;
}

function renderNewAccountInChild({ key, flow, openModal }) {
  return <NewAccount key={key} flow={flow} openModal={openModal} />;
}

function renderCardAccountInChild({ key, account, handleChange, isSelected }) {
  return (
    <CardAccount
      key={key}
      account={account}
      handleChange={handleChange}
      isSelected={isSelected}
    />
  );
}

function ExpensesUpload() {
  const [expenses, setExpenses] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const [checkZoho, setCheckZoho] = useState(false);
  const [billing, setBilling] = useState(INITIAL_STATE);
  const [startZohoEmail, setStartZohoEmail] = useState("1");
  const [numberPageZoho, setNumberPageZoho] = useState(1);
  const [quantityEmails, setQuantityEmails] = useState("Todas");
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [nextPage, setNextPage] = useState("");

  const { getEmails, checkTokenEmail, getTokenEmail, getEmailsAccounts } =
    useZoho();
  const { LineDetailPlace, dates, accountSelected, setAccountSelected } =
    useElectronic();

  const { handleElectronicBillsXML } = useElectronicBill();

  const { permissions, state } = useAuth();
  const { setModalOpen } = useModal();
  const navigate = useNavigate();
  const { setModalOpen: setDetailModalOpen } = useModal();
  const { setModalOpen: setManagentAccountsModalOpen } = useModal();

  const { haveActionPermission, noAccessMessage } = permissions;
  const canCreate = haveActionPermission(
    "Create",
    "/expenses",
    LineDetailPlace,
  );

  const { user } = state;

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const server = queryParams.get("accounts-server");

  const handleSelectAllClick = () => {
    // evento del botón "Seleccionar todas"
    // Selecciona todas las facturas en el estado expenses
    setExpenses(prev =>
      prev.map(expense => ({ ...expense, selected: !allSelected })),
    );
    setAllSelected(prev => !prev);
  };

  const handleSelect = (name, value) => {
    // Selecciona la factura indicada en el estado expenses
    setExpenses(prev =>
      prev.map(expense =>
        expense.Clave._text === name
          ? { ...expense, selected: value }
          : expense,
      ),
    );
  };

  const setBillingProfile = BillingProfile =>
    setBilling(prv => ({ ...prv, BillingProfile }));

  useEffect(() => {
    const createElectronicBillProfile = () => {
      setModalOpen(
        true,
        <ProfileBillWrapper
          initialReceiver={billing?.BillingProfile}
          setBillingProfile={setBillingProfile}
          setModalOpen={setModalOpen}
        />,
      );
    };
    if (state?.user?.TavuelUser?.BillingProfile === null) {
      createElectronicBillProfile();
    }
  }, [state, billing, setModalOpen]);

  const saveZohoToken = useCallback(async () => {
    const getTypeMail = () => {
      if (code.includes("M.R3_BAY")) {
        return parseInt(EMAIL_PROVIDERS.Outlook, 10);
      }
      return server
        ? parseInt(EMAIL_PROVIDERS.Zoho, 10)
        : parseInt(EMAIL_PROVIDERS.Gmail, 10);
    };

    const selectPlace = async (FK_Place, bill) => {
      setModalOpen(false);
      const response = await getTokenEmail(
        user.googleAuthId,
        code,
        getTypeMail(),
        FK_Place,
      );
      if (!response?.data?.email) {
        toast.error("Error al iniciar sesión");
        return;
      }
      setAccountSelected(response?.data?.email);
      toast.success("¡Sesión iniciada con éxito!");
    };

    try {
      setModalOpen(
        true,
        <Inventories
          isModalMode
          color="secondaryInventory"
          setModalClose={setModalOpen}
          title="Seleccione el Lugar de la Compañia"
          onClickFunction={selectPlace}
        />,
        "No se seleccionó un lugar para poder finalizar el inicio de sesión.",
        true,
      );
    } catch (err) {
      toast.error("Error al iniciar sesión");
    }
  }, [
    getTokenEmail,
    user.googleAuthId,
    code,
    server,
    setModalOpen,
    setAccountSelected,
  ]);

  useEffect(() => {
    if (code) {
      saveZohoToken();
      navigate("upload-expenses");
    }
  }, [code, navigate, saveZohoToken]);

  const allAccounts = useCallback(async () => {
    try {
      const res = await getEmailsAccounts(user.googleAuthId);
      setAccounts(res?.data?.data);
    } catch (err) {
      toast.error(err);
    }
  }, [getEmailsAccounts, user.googleAuthId]);

  useEffect(() => {
    user?.googleAuthId && allAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCleanBills = () => {
    setExpenses([]);
    setStartZohoEmail("1");
    setNumberPageZoho(1);
  };

  const isValidXMLType = type => /text\/(xml)/.test(type);

  const isAlreadyUploaded = useCallback(
    facturaJSON =>
      expenses
        .map(({ Clave: { _text } }) => _text)
        .includes(facturaJSON.FacturaElectronica.Clave._text),
    [expenses],
  );

  const removeDuplicatedXMLByKey = xmls =>
    xmls.reduce(
      (acc, xml) => {
        if (!acc.dataKeys.includes(xml?.FacturaElectronica?.Clave?._text)) {
          acc.data.push(xml);
          acc.dataKeys.push(xml.FacturaElectronica?.Clave?._text);
        }
        return acc;
      },
      { data: [], dataKeys: [] },
    );

  const xmlFilesToJson = async files => {
    const data = await Promise.allSettled(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target: { result } }) => {
              try {
                const bill = resolve(
                  JSON.parse(
                    converter.xml2json(result, { compact: true, spaces: 4 }),
                  ),
                );
                resolve(bill);
              } catch (e) {
                reject(e);
              }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
          }),
      ),
    );

    const succses = await data
      .filter(({ status }) => status === "fulfilled")
      .map(({ value }) => value);

    const failed = data.filter(({ status }) => status === "rejected").length;
    failed && toast.error(`No se lograron abrir ${failed} documentos`);

    return succses;
  };

  const readXml = async files => {
    const uniqueXmls = removeDuplicatedXMLByKey(files).data;

    uniqueXmls.forEach(facturaJSON => {
      if (!validationIdEquals(facturaJSON)) {
        toast.error(
          "Verifique que todas las facturas coincidan con su perfil de facturación",
        );
        return;
      }
      if (isAlreadyUploaded(facturaJSON)) {
        toast.error(
          `La factura ...${facturaJSON.FacturaElectronica.Clave._text.slice(
            -11,
          )} ya fue subida anteriormente `,
        );
        return;
      }
      setExpenses(prev => [
        ...prev,
        {
          ...facturaJSON?.FacturaElectronica,
          selected: true,
          isOutOfDateRange: facturaJSON?.isOutOfDateRange,
        },
      ]);
    });
  };

  const validationIdEquals = dataJSON => {
    try {
      if (state?.user?.TavuelUser?.UserBillingProfile) {
        const { ID_Number } = state?.user?.TavuelUser?.UserBillingProfile || {};
        const { Numero: DocumentId_Receptor } =
          dataJSON.FacturaElectronica?.Emisor?.Identificacion || {};

        return ID_Number === DocumentId_Receptor._text;
      }
      return null;
    } catch (err) {
      return false;
    }
  };

  const onDrop = async Files =>
    readXml(
      await xmlFilesToJson(Files.filter(file => isValidXMLType(file.type))),
    );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".xml" });

  const handleSave = async () => {
    if (!canCreate) {
      toast.error(noAccessMessage("Crear", "Egresos"));
      return;
    }
    if (LineDetailPlace.id === 0) {
      toast.error("No se ha seleccionado un Negocio");
      return;
    }

    const request = chunk(expenses, 5).map(async xChucnk =>
      handleElectronicBillsXML(
        state.user.TavuelUser,
        xChucnk,
        LineDetailPlace.id,
        1,
      ),
    );

    const messages = await Promise.all(request, { concurrency: 3 }).reduce(
      (finalMessages, current) => {
        const { response, message, success } = current;
        if (response === "errors") {
          finalMessages.errors.push(...message);
        }
        if (response === "error") {
          return Object.assign(finalMessages, { error: message });
        }
        if (success) {
          return Object.assign(finalMessages, { success });
        }
        return finalMessages;
      },
      { errors: [], sucess: undefined, error: null },
    );

    messages.success && toast.success(messages.success);
    messages.errors.forEach(bill => {
      if (bill !== "") {
        toast.error(
          `La factura:
        ${bill},
        ya se encuentra registrada,
        favor revisar las facturas`,
        );
      }
    });

    messages.error && toast.error(messages.error);
  };

  const searchDocuemnts = async start => {
    setIsLoadingBills(true);
    const { data: isTokenValid } = await checkTokenEmail(
      accountSelected?.id,
      parseInt(accountSelected?.type, 10),
    );
    if (!isTokenValid.zoho) {
      setCheckZoho(false);
    }
    const { data } = await getEmails(
      accountSelected?.id,
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.startDate.value)
        : dates.startDate.value.replaceAll("-", "/"),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.endDate.value)
        : dates.endDate.value.replaceAll("-", "/"),
      quantityEmails,
      start,
      nextPage,
      parseInt(accountSelected?.type, 10),
    );
    const xmls = data.data.map(email => getXMLFromEmailFiles(email.files));
    if (accountSelected?.type === EMAIL_PROVIDERS.Gmail && xmls?.length > 0) {
      setNextPage(data.data[0].nextPage.toString());
    }
    readXml(xmls.flat());
    setIsLoadingBills(false);
  };

  const getXMLFromEmailFiles = emailFiles =>
    emailFiles
      .map(file => {
        try {
          return JSON.parse(
            converter.xml2json(
              accountSelected.type === EMAIL_PROVIDERS.Gmail
                ? decodeBase64(file.content)
                : file.content,
              { compact: true },
            ),
          );
        } catch (e) {
          return { error: true };
        }
      })
      .filter(xmlJSON => !xmlJSON.error && !xmlJSON.MensajeHacienda);

  const decodeBase64 = str => {
    const response = str.replace(/_/g, "/").replace(/-/g, "+"); // important line
    return atob(response);
  };

  const handleNextZohoEmail = () => {
    setStartZohoEmail(
      (parseInt(startZohoEmail, 10) + parseInt(quantityEmails, 10)).toString(),
    );

    searchDocuemnts(
      (parseInt(startZohoEmail, 10) + parseInt(quantityEmails, 10)).toString(),
    );
    setNumberPageZoho(numberPageZoho + 1);
  };

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[accountSelected?.type];
  };

  return expenses?.length === 0 ? (
    <div className="content-flex-column">
      {accountSelected.id !== 0 ? (
        <div className="container-switch-email">
          <div className="switch-email">
            <Container
              className="btn-switch container-icon-google"
              sx={{
                maxheight: "50px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setManagentAccountsModalOpen(
                  true,
                  <AccountManagement
                    CardAccount={renderCardAccountInChild}
                    NewAccount={renderNewAccountInChild}
                    openModal={setManagentAccountsModalOpen}
                  />,
                );
              }}
            >
              <Box
                sx={{
                  background: "#ffff",
                  borderRadius: "61px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  justifySelf: "center",
                  height: "29px",
                  width: "32px",
                }}
              >
                <img
                  alt="icon"
                  className={getCssImage()}
                  src={getImageEmail(accountSelected.type)}
                />
              </Box>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "200px",
                }}
                typography="modeColor"
                variant="body1"
                width="100%"
              >
                {accountSelected?.email}
              </Typography>
            </Container>
          </div>
        </div>
      ) : (
        <div className="content-flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              accounts?.length > 0
                ? setManagentAccountsModalOpen(
                    true,
                    <AccountManagement
                      CardAccount={renderCardAccountInChild}
                      NewAccount={renderNewAccountInChild}
                      openModal={setManagentAccountsModalOpen}
                    />,
                  )
                : setManagentAccountsModalOpen(
                    true,
                    <NewAccount
                      flow
                      AccountManagement={renderAccountManagementInChild}
                      CardAccount={renderCardAccountInChild}
                      openModal={setManagentAccountsModalOpen}
                    />,
                  );
            }}
          >
            <p className="has-text-white">Iniciar Sesión</p>
          </Button>
        </div>
      )}
      {accountSelected.id !== 0 && (
        <ZohoSearch
          isLogged={checkZoho}
          nextPage={nextPage}
          quantityEmails={quantityEmails}
          setIsLogged={setCheckZoho}
          setNextPage={setNextPage}
          setQuantityEmails={setQuantityEmails}
          setXmls={readXml}
        />
      )}
      <Card
        color="secondaryFile"
        {...getRootProps({
          className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
        })}
      >
        <Box
          className="content-flex-column"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <img
            alt="img"
            src={fileIcon}
            style={{ width: "76px", height: "90px" }}
          />
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography typography="defaultLight" variant="subtitle1">
              Suelte los archivos aquí...
            </Typography>
          ) : (
            <Typography typography="defaultLight" variant="subtitle1">
              Arrastre los archivos .xml aquí, o click para seleccionar archivos
            </Typography>
          )}
        </Box>
      </Card>
    </div>
  ) : (
    <div className="content-flex-column">
      <div className="content-flex-end">
        <Button
          color="secondary"
          variant="contained"
          onClick={handleCleanBills}
        >
          Limpiar pantalla
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSelectAllClick}
        >
          {!allSelected ? "Seleccionar todas" : "Deseleccionar todas"}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Guardar
        </Button>
      </div>
      <div
        className={
          quantityEmails !== "Todas"
            ? "buttons-zoho-more"
            : "hide-buttons-zoho-more"
        }
      >
        <div className="zoho-card">
          <img
            alt="icon"
            className={
              accountSelected.type === EMAIL_PROVIDERS.Zoho
                ? "img-zoho-expense"
                : "img-gmail-expense"
            }
            src={getImageEmail(accountSelected.type)}
          />
        </div>
        <div className="zoho-number-page">{numberPageZoho}</div>
        <Button
          color="primary"
          disabled={expenses.length !== parseInt(quantityEmails, 10)}
          onClick={() => handleNextZohoEmail()}
        >
          Cargar más
        </Button>
      </div>
      {isLoadingBills && <CircularProgress disableShrink />}
      <div className="content-flex-cards">
        <Card
          color="secondaryFile"
          {...getRootProps({
            className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
          })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography typography="defaultLight" variant="subtitle1">
              Suelte los archivos aquí...
            </Typography>
          ) : (
            <Typography typography="defaultLight" variant="subtitle1">
              Arrastre los archivos .xml aquí, o click para seleccionar archivos
            </Typography>
          )}
        </Card>

        {expenses.map(expense => {
          const [dateOfIssueFormattedDate, dateOfIssueFormattedTime] =
            formatDateString(expense?.FechaEmision?._text);

          return (
            <Card
              key={expense?.Clave?._text}
              color="primary"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "1rem",
                height: "333px",
                width: { xs: "100%", sm: "300px" },
                padding: "1rem",
              }}
            >
              <div className="content-flex-end">
                <BooleanInput
                  name={expense?.Clave._text}
                  value={expense?.selected}
                  onChange={(name, value) => handleSelect(name, value ? 1 : 0)}
                />
              </div>
              <Typography
                align="start"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "280px",
                }}
                typography="modeColor"
                variant="subtitle1"
              >
                <span className="has-text-weight-bold"> Emisor: </span>

                {expense?.Emisor?.Nombre?._text}
              </Typography>
              <Typography
                align="start"
                typography="modeColor"
                variant="subtitle1"
              >
                <span className="has-text-weight-bold"> Cédula: </span>{" "}
                {expense?.Emisor?.Identificacion?.Numero?._text}
              </Typography>
              <Typography typography="modeColor" variant="subtitle1">
                <span className="has-text-weight-bold"> Moneda: </span>{" "}
                {expense?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
                  ?._text || "CRC"}
              </Typography>
              <Typography typography="modeColor" variant="subtitle1">
                <span className="has-text-weight-bold"> Total: </span>{" "}
                {formatCurrency(
                  parseFloat(
                    expense?.ResumenFactura?.TotalVentaNeta?._text,
                    10,
                  ),
                  expense?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
                    ?._text || "CRC",
                )}
              </Typography>
              <Typography typography="modeColor" variant="subtitle1">
                <span className="has-text-weight-bold"> Fecha: </span>{" "}
                {`${dateOfIssueFormattedDate} ${dateOfIssueFormattedTime}`}
                {expense?.isOutOfDateRange && (
                  <div style={{ marginLeft: "5px" }}>
                    <FontAwesomeIcon
                      data-tip
                      data-for="xmlInfo"
                      icon="info-circle"
                    />
                    <Tooltip
                      effect="solid"
                      id="xmlInfo"
                      place="bottom"
                      type="dark"
                    >
                      <span>Fecha de Factura fuera del rango seleccionado</span>
                    </Tooltip>
                  </div>
                )}
              </Typography>
              <div className="content-flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    setDetailModalOpen(
                      true,
                      <BillUploadDetail
                        electronicDocument={arrayBill(expense)}
                      />,
                    )
                  }
                >
                  Ver detalles
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default ExpensesUpload;
