/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
// External components
import FormControl from "@mui/material/FormControl";
import { components } from "react-select";
// Hooks
import useAsyncSearch from "../../hooks/useAsyncSearch";
// Graphql
import { GET_CABYS_BY_CRITERIA } from "../../graphql";
import styleAsynSelect from "./styles";

function CustomOption(value) {
  const { data } = value;
  const option = data;
  return (
    <components.Option {...value}>
      {option && (
        <div className="option-container">
          <div className="option-content-left">
            <div className="product-header">
              <p>
                <b>{option.value}</b>
              </p>
              <p>{option.label}</p>
            </div>
          </div>
        </div>
      )}
    </components.Option>
  );
}

function CabysCodeSelect({ handleChange, defaultValue, selected_value }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { getResults, DebouncedLoad } = useAsyncSearch();

  const loadOptions = async inputValue => {
    const variables = {
      criteria: inputValue,
    };

    const data = await getResults(GET_CABYS_BY_CRITERIA, variables);

    const codes = data?.cabys || [];
    const result = codes.map(item => ({
      value: item.Name,
      label: item.Code,
      percent: item?.Percent,
      id: item.id,
    }));
    setDefaultOptions(result);
    return result;
  };

  const debouncedLoad = DebouncedLoad(loadOptions, 1000);

  return (
    <FormControl fullWidth>
      <AsyncSelect
        cacheOptions
        className="input second-input without-padding"
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        loadOptions={debouncedLoad}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar Código CABys"
        styles={styleAsynSelect}
        value={selected_value}
        onChange={handleChange}
      />
    </FormControl>
  );
}

CabysCodeSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  selected_value: PropTypes.object.isRequired,
};
CabysCodeSelect.defaultProps = {
  defaultValue: null,
};

export default CabysCodeSelect;
