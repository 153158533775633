import { Card, Typography } from "@mui/material";
import PropTypes from "prop-types";

function TitledCard({ title, children, sx }) {
  return (
    <Card
      color="primary"
      sx={{
        paddingY: 4,
        paddingX: 3,
        ...sx,
      }}
    >
      {title && (
        <Typography
          sx={{ fontSize: 20, fontWeight: 700, marginBottom: 4 }}
          variant="h1"
        >
          {title}
        </Typography>
      )}
      {children}
    </Card>
  );
}

TitledCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  sx: PropTypes.object,
};
TitledCard.defaultProps = {
  title: undefined,
  sx: undefined,
};

export default TitledCard;
