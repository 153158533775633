const PAYMENT_METHODS = {
  EFECTIVO: { ID: "1", NAME: "Efectivo" },
  TARJETA: { ID: "2", NAME: "Tarjeta" },
  CHEQUEl: { ID: "3", NAME: "Cheque" },
  TRANSFERENCIA: { ID: "4", NAME: "Transferencia-Depósito bancario" },
  REC_OTROS: { ID: "5", NAME: "Recaudado por terceros" },
  OTROS: { ID: "6", NAME: "Otros" },
};

export default PAYMENT_METHODS;
