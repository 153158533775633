import { useMutation } from "@apollo/client";
import { useElectronic } from "../context/ElectronicContext";
import { CREATE_DRAFT_FEC, CREATE_FEC, CREATE_FEC_PRODUCTS } from "../graphql";
import { client_EB_api } from "../graphql/client";
import { getPlaceBillingProfileToUse } from "../utils/placeUtils";

const useBuyBill = () => {
  const [createTempBill] = useMutation(CREATE_DRAFT_FEC);
  const [createFECProducts] = useMutation(CREATE_FEC_PRODUCTS);
  const { useCoinDetail, electronicBill, LineDetailPlace, receiver } =
    useElectronic();
  const { currentCoin } = useCoinDetail;

  /**
   * @param {{iv: number, amo: number, exp:string,
   * tax:string, quantity:number, measurmentUnit:number,
   * total:number, discount:number } []} details products that will be added
   */
  const handleCreateFECProducts = async input =>
    createFECProducts({
      variables: {
        createFECProductsInput: {
          FK_User: receiver?.user?.id,
          productInput: input.productInput.map(product => ({
            unitPrice: product.unitPrice,
            subTotal: product.subTotal,
            Name: product.name,
            MeasurementUnit: product.measurmentUnit?.id,
            totalPrice: product.total,
            quantity: parseInt(product.quantity, 10),
            discount: product.discountPercent,
            baseTax: product.baseTax,
            factor: product.factor,
            FK_Tax: product?.tax?.id,
            FK_Tariff: product?.tariff?.id,
            Cabys: product?.cabys?.id,
          })),
        },
      },
    });

  const handleCreatDraftFEC = async details =>
    createTempBill({
      variables: {
        createDraftFECInput: {
          billInput: {
            FK_Coin: currentCoin,
            FK_SellCondition: electronicBill.Sell_Condition,
            FK_PaymentMethod: electronicBill.Payment_Method,
            FK_User: receiver?.user?.id,
            FK_Place: LineDetailPlace?.id,
            FK_BillingProfile: getPlaceBillingProfileToUse(LineDetailPlace)?.id,
          },
          productInput: details.map(detail => ({
            unitPrice: detail.unitPrice,
            subTotal: detail.subTotal,
            Name: detail.Name ?? detail.name,
            MeasurementUnit:
              detail.MeasurementUnit ?? detail.measurmentUnit?.id,
            totalPrice: detail.total,
            quantity: parseInt(detail.quantity, 10),
            discount: detail.discountPercent ?? detail.discount,
            baseTax: detail.baseTax,
            factor: detail.factor,
            FK_Tax: detail?.FK_Tax ?? detail?.tax?.id,
            FK_Tariff: detail?.tariff?.id,
            Cabys: detail?.Cabys,
            productFeature_FK: detail.productFeature_FK,
          })),
        },
      },
    });

  const handleCreateFEC = async id =>
    client_EB_api.mutate({
      mutation: CREATE_FEC,
      variables: {
        electronicBillContInput: {
          id,
        },
      },
    });

  return { handleCreatDraftFEC, handleCreateFEC, handleCreateFECProducts };
};
export default useBuyBill;
