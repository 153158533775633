import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import { MHUser, P12 as P12Upload } from "./components";

function MHData({
  setModalOpen,
  billingProfile,
  setFileEB,
  setMHUser,
  disabled,
}) {
  return (
    <Box>
      <Typography
        fontWeight="bold"
        sx={{ marginY: "1rem" }}
        textAlign="center"
        variant="h6"
      >
        P12 y Credenciales Ministerio Hacienda
      </Typography>
      <MHUser
        billingProfile={billingProfile}
        disabled={disabled || !billingProfile}
        onChange={setMHUser}
      />
      <P12Upload
        MH_User={billingProfile?.MH_User}
        disabled={disabled || !billingProfile}
        setModalOpen={setModalOpen}
        onChange={setFileEB}
      />
    </Box>
  );
}

MHData.propTypes = {
  setModalOpen: PropTypes.func,
  setFileEB: PropTypes.func,
  setMHUser: PropTypes.func,
  billingProfile: PropTypes.any,
  disabled: PropTypes.bool,
};

MHData.defaultProps = {
  setModalOpen: state => null,
  setFileEB: state => null,
  setMHUser: state => null,
  billingProfile: null,
  disabled: false,
};

export default MHData;
