import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Box, Button, Card, Typography } from "@mui/material";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "../OptionCard/OptionCard.scss";
import InputForm from "../InputForm/InputForm";
// ICON
import addPerson from "../../assets/icons/addPerson_icon.svg";
import deletePerson from "../../assets/icons/deletePerson_icon.svg";

function ClientCard({
  mainComponent,
  type,
  data,
  onClickSubmit,
  onClickCancel,
  sx,
}) {
  const navigate = useNavigate();
  const { setIsBilling, resetReceiver } = useElectronic();

  const handleChangeRoute = () => {
    if (
      type !== ElectronicBillTypes.CreditNote &&
      type !== ElectronicBillTypes.DebitNote
    ) {
      setIsBilling(true);
      navigate("/electronic-bills/client");
    }
  };

  const removeClient = e => {
    e.stopPropagation();
    resetReceiver();
  };

  const isProvider = !mainComponent && type === "BuyElectronicBill";
  const isClient = !mainComponent && type !== "BuyElectronicBill";
  return (
    <Card
      color="primary"
      sx={{
        minHeight: "100px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
        width: "100%",
        ...sx,
      }}
    >
      {isClient && (
        <Box sx={{ display: "flex" }}>
          <Typography
            fontWeight={600}
            typography="modeColor"
            variant="h6"
            width="100%"
          >
            Cliente
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {data.Email && (
              <Button
                className="p-1"
                color="secondary"
                disabled={!data.Email}
                sx={{
                  justifyContent: "right",
                }}
                onClick={e => removeClient(e)}
              >
                <img
                  alt="eliminar"
                  className="icon-button icon-color"
                  src={deletePerson}
                />
              </Button>
            )}
          </Box>
        </Box>
      )}

      {isProvider && (
        <Box sx={{ display: "flex" }}>
          <Typography
            fontWeight={600}
            typography="modeColor"
            variant="h5"
            width="100%"
          >
            Proveedor
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {data.Email && (
              <Button
                className="p-1"
                color="secondary"
                disabled={!data.Email}
                sx={{
                  justifyContent: "right",
                }}
                onClick={onClickCancel}
              >
                <img
                  alt="eliminar"
                  className="icon-button icon-color"
                  src={deletePerson}
                />
              </Button>
            )}
          </Box>
        </Box>
      )}
      {mainComponent && (
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Cliente
        </Typography>
      )}
      {!data.Email && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Button
            className="p-1"
            color="primary"
            sx={{
              justifyContent: "center",
            }}
            onClick={onClickSubmit}
          >
            <img
              alt="agregar"
              className="icon-button icon-color"
              src={addPerson}
            />
          </Button>
          <Button
            className="p-1"
            color="secondary"
            style={{}}
            onClick={handleChangeRoute}
          >
            <Typography typography="modeColor" variant="subtitle1" width="100%">
              Click para buscar(opcional)
            </Typography>
          </Button>
        </Box>
      )}
      {/* pointerEvents: "none" */}
      {((isClient && data.Email) || (isProvider && data.Email)) && (
        <div className="content-flex-column">
          <Box className="content-grid-column">
            <InputForm
              color="secondary"
              disabled="true"
              id="Name"
              label="Nombre completo"
              name="Name"
              size="small"
              sx={{ pointerEvents: "none" }}
              value={data.Name}
              variant="filled"
            />
            <InputForm
              color="secondary"
              disabled="true"
              id="Id"
              label="Cédula"
              name="Id"
              size="small"
              sx={{ pointerEvents: "none" }}
              value={data.Id}
              variant="filled"
            />
          </Box>
          <Box className="content-grid-column">
            <InputForm
              color="secondary"
              disabled="true"
              id="phoneNumber"
              label="Teléfono"
              name="phoneNumber"
              size="small"
              sx={{ pointerEvents: "none" }}
              value={data.PhoneNumber}
              variant="filled"
            />
            <InputForm
              color="secondary"
              disabled="true"
              id="email"
              label="Correo"
              name="email"
              size="small"
              sx={{ pointerEvents: "none" }}
              value={data.Email}
              variant="filled"
            />
          </Box>
        </div>
      )}
    </Card>
  );
}
ClientCard.propTypes = {
  mainComponent: PropTypes.bool,
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  onClickSubmit: PropTypes.func,
  onClickCancel: PropTypes.func,
  sx: PropTypes.object,
};
ClientCard.defaultProps = {
  mainComponent: false,
  data: [],
  onClickSubmit: undefined,
  onClickCancel: undefined,
  sx: undefined,
};

export default ClientCard;
