import React, { useState, useEffect, useRef, useCallback } from "react";
// External components
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  RadioGroup,
  Typography,
} from "@mui/material";
// Graphql
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  REGISTER_NEW_PLACE_AND_USER_ROLE,
  GET_PLACE_BY_NAME,
  GET_ALL_COMPANIES,
  DEFAULT_BILLING_PROFILE,
  GET_ALL_PLACES_BY_USER,
} from "../../graphql";
import { client_EB_api } from "../../graphql/client";
// Context
import { useModal, useAuth } from "../../context";
import usePlaces from "../../hooks/usePlaces";
import { useElectronic } from "../../context/ElectronicContext";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import UserToPlace from "../UserToPlace";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import RegisterReceiver from "../RegisterReceiver";
import MHData from "../MHData";
import SquareRadioCheck from "../SquareRadioButton/SquareRadioButton";
// Utils
import {
  customToast as toast,
  encrypt,
  validateEmail,
  validateStringLength,
  validateFormObject,
} from "../../utils";
import { validateID } from "../../utils/idDocument";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";

function PlacesRegister() {
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [placeForm, setPlaceForm] = useState({
    Name_Place: "",
    Description_Place: "",
    State_Place: "1",
    FK_Company: 0,
    Code: 0,
    Files: null,
    Contacts: [],
    PlaceTags: [],
    Gmail: "",
    Gmail_App: "",
    IsUsingDefaultBillingProfile: true,
    BillingProfile: undefined,
  });
  const [auxBillingProfile] = useState({});
  const [defaultBillingProfile, setDefaultBillingProfile] = useState({});
  const [company, setCompany] = useState();
  const { GetPLacesByUserIdAndModuleId } = usePlaces();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { state } = useAuth();
  const user = state.user.TavuelUser;
  const { setModalOpen } = useModal();
  const { patchBillingProfile } = useElectronic();

  const { data: getCompanyData } = useQuery(GET_ALL_COMPANIES, {
    variables: {
      id: user?.id,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [_, dataPlaces, __, refetch] = GetPLacesByUserIdAndModuleId(user?.id);

  const canvas = useRef(null);

  const [getplaceByName, { data: placebyName }] =
    useLazyQuery(GET_PLACE_BY_NAME);
  const [registerPlace] = useMutation(REGISTER_NEW_PLACE_AND_USER_ROLE, {
    update: (cache, { data: { place } }) => {
      const existingUser = cache.readQuery({ query: GET_ALL_PLACES_BY_USER });

      cache.writeQuery({
        query: GET_ALL_PLACES_BY_USER,
        data: {
          user: {
            ...existingUser?.user,
            IsUsingDefaultBillingProfile: place.IsUsingDefaultBillingProfile,
          },
        },
      });
    },
  });

  const validateRoute = () => {
    if (firebaseURL === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlaceForm(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event, newValue) => {
    setPlaceForm(prev => ({ ...prev, Code: newValue.value }));
  };

  const createPlaceFullProcess = useCallback(
    async ({ placeData, address, FK_User, billingProfile }) => {
      const Origin = process.env.REACT_APP_NAME_CONTA;
      if (!Origin) throw new Error("No se pudo encontrar un origen");

      const { data } = await registerPlace({
        variables: {
          place: placeData,
          address,
          FK_User,
          Origin,
        },
      });

      if (!data)
        return {
          place: undefined,
          error: "No se obtuvo respuesta del servidor",
        };

      const { place } = data;

      if (!place) {
        const resp = await getplaceByName({
          variables: {
            name: placeData?.Name_Place,
          },
        });
        if (resp)
          return {
            place: undefined,
            error: "Ya existe un lugar con ese nombre",
          };
      }

      if (!billingProfile) return { place, error: undefined };

      const resp = await patchBillingProfile({
        ...billingProfile,
        FK_Place: place.id,
      });

      if (!resp)
        return {
          place: undefined,
          error: "Error al crear el perfil de facturación",
        };

      return { place, error: undefined };
    },
    [getplaceByName, patchBillingProfile, registerPlace],
  );

  const register = useCallback(async () => {
    try {
      const billingProfileValidationConfig = {
        fieldLabels: {
          Name: "Nombre",
          Lastname: "Apellido",
          MH_PersonType: "Tipo de Persona",
          ID_Number: "Número de Identificación",
          Email: "Correo Electrónico",
          PhoneNumber: "Número de Teléfono",
          PhoneCodCountry: "Código de País (Teléfono)",
          MH_Province: "Provincia",
          MH_Canton: "Cantón",
          MH_District: "Distrito",
          MH_Neighborhood: "Barrio",
          OtherSigns: "Otras Señas",
          Tax_Registration: "Registro Fiscal8707",
        },
        customValidations: {
          Email: [
            {
              validate: validateEmail,
              message: "Correo electrónico no válido",
            },
          ],
          Name: [
            {
              validate: validateStringLength({ minLength: 3 }),
              message: "El nombre debe tener al menos 3 caracteres",
            },
          ],
          PhoneNumber: [
            {
              validate: validateStringLength({ minLength: 8 }),
              message: "El número de teléfono debe tener al menos 8 caracteres",
            },
          ],
          ID_Number: [
            {
              validate: idValue =>
                !validateID({
                  id: idValue,
                  idType: placeForm.BillingProfile.MH_PersonType,
                }),
              message: idValue =>
                validateID({
                  id: idValue,
                  idType: placeForm.BillingProfile.MH_PersonType,
                }),
            },
          ],
        },
        exceptions: ["id", "MH_User", "ComercialName", "IDForeign"],
      };
      if (!placeForm.IsUsingDefaultBillingProfile) {
        const validationRespone = validateFormObject(
          placeForm.BillingProfile,
          billingProfileValidationConfig,
        );
        if (!validationRespone.isValid) {
          toast.error(validationRespone.message);
          return;
        }
      }

      const appOrigin = process.env.REACT_APP_NAME_CONTA;

      if (!appOrigin) throw new Error("No se pudo encontrar un origen");

      const defaultAddress = {
        Name_Address: "new place",
        Description_Address: "new place",
        State_Address: 3,
      };

      const { BillingProfile, DefaultBillingProfile, Gmail_App, ...placeData } =
        placeForm;

      const billingProfileValue = placeData.IsUsingDefaultBillingProfile
        ? undefined
        : {
            ...BillingProfile,
            Name: `${BillingProfile.Name} ${BillingProfile.Lastname}`,
            FK_User: undefined,
            Lastname: undefined,
          };

      const { place, error } = await createPlaceFullProcess({
        address: defaultAddress,
        billingProfile: billingProfileValue,
        FK_User: user?.id,
        placeData: { ...placeData, Gmail_App: encrypt(Gmail_App) },
      });

      if (error) {
        toast.error(error);
        if (placebyName?.place?.Company?.id) setIsOpen(true);
        return;
      }

      refetch();
      toast.success("¡Lugar registrado con éxito!");

      navigate(`/places/update-place/${place.id}`);
    } catch (err) {
      toast.error("Error al registrar el lugar");
    }
  }, [
    placeForm,
    createPlaceFullProcess,
    user?.id,
    refetch,
    navigate,
    placebyName?.place?.Company?.id,
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    if (placeForm.Name_Place !== "" && placeForm.Description_Place !== "") {
      register();
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
  };
  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getAndStoreDefaultBillingProfile = async () => {
    const {
      data: { BillingProfile },
    } = await client_EB_api.query({
      query: DEFAULT_BILLING_PROFILE,
    });

    setDefaultBillingProfile(BillingProfile);
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlaceForm(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (placebyName?.place?.id) {
      if (placebyName?.place?.Company?.id) {
        setIsOpen(true);
      }
    }
  }, [placebyName]);

  useEffect(() => {
    if (isOpen) {
      setModalOpen(
        true,
        <UserToPlace
          place={placebyName?.place}
          onClose={() => {
            setModalOpen(false);
          }}
        />,
      );
      setIsOpen(false);
    }
  }, [placebyName?.place, isOpen, setModalOpen]);

  // COMPANY DATA
  useEffect(() => {
    setCompany(getCompanyData?.companies[0]);
    setPlaceForm(prev => ({
      ...prev,
      FK_Company: getCompanyData?.companies[0].id,
    }));
    if (getCompanyData?.companies.length === 0) {
      setCompany(dataPlaces?.places[0].Company);
    }
  }, [dataPlaces?.places, getCompanyData, getCompanyData?.companies]);

  useEffect(() => {
    if (!user?.id) return;

    getAndStoreDefaultBillingProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <form className="profile-page-container" onSubmit={handleSubmit}>
      <Grid
        container
        margin={0}
        sx={{ paddingX: { xs: 2, md: 7, lg: 11, xl: 17 } }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            marginBottom={5}
            textAlign="center"
            typography="modeColor"
            variant="h5"
          >
            Registro de Lugares
          </Typography>
        </Grid>
        <Grid item paddingTop={5} textAlign="center" xs={12}>
          <Button
            component="label"
            sx={{ aspectRatio: "square", width: 111, listStyle: "none" }}
          >
            <Input hidden type="file" onChange={hadleOnSelectFile} />
            <Box
              component="img"
              src={validateRoute()}
              sx={{ objectFit: "cover" }}
            />
          </Button>
        </Grid>
        <Grid item paddingTop={5} xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                label="Compañía"
                size="small"
                type="text"
                typeInput="text"
                value={company?.Name_Company}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectActivityCode
                defaultValue={{ label: "", value: "" }}
                handleChange={handleSelectChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Name_Place"
                label="Nombre"
                name="Name_Place"
                placeholder="Nombre"
                size="small"
                type="text"
                typeInput="text"
                value={placeForm?.Name_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Gmail"
                label="Correo Gmail"
                name="Gmail"
                placeholder="Correo Gmail"
                size="small"
                type="email"
                typeInput="email"
                value={placeForm.Gmail}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Description_Place"
                label="Descripción"
                name="Description_Place"
                placeholder="Descripción"
                size="small"
                type="text"
                typeInput="text"
                value={placeForm?.Description_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Gmail_App"
                label="Contraseña de aplicación Gmail"
                name="Gmail_App"
                placeholder="Contraseña de aplicación Gmail"
                size="small"
                type={isPasswordVisible ? "text" : "password"}
                typeInput="password"
                value={placeForm.Gmail_App}
                onChange={e => handleChange(e.target.name, e.target.value)}
                onClickPassword={() => setIsPasswordVisible(prev => !prev)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item paddingTop={5} xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              row
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: { xs: 2, sm: 2, md: 6, lg: 12 },
              }}
              value={placeForm.IsUsingDefaultBillingProfile ? "default" : "own"}
              onChange={({ target: { value } }) =>
                handleChange(
                  "IsUsingDefaultBillingProfile",
                  value === "default",
                )
              }
            >
              <FormControlLabel
                control={<SquareRadioCheck />}
                label="Utilizar perfil de facturación de usuario"
                value="default"
              />
              <FormControlLabel
                control={<SquareRadioCheck />}
                label="Utilizar perfil de facturación nuevo"
                value="own"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: placeForm.IsUsingDefaultBillingProfile
                ? "block"
                : "none",
            }}
          >
            <RegisterReceiver
              disabled
              hideFormButtons
              isAdmin
              FK_User={user?.id}
              initialReceiver={defaultBillingProfile || {}}
              title="Perfil de facturación"
            />
          </Box>
          <Box
            sx={{
              display: placeForm.IsUsingDefaultBillingProfile
                ? "none"
                : "block",
            }}
          >
            <RegisterReceiver
              hideFormButtons
              isAdmin
              FK_User={user?.id}
              initialReceiver={auxBillingProfile}
              title="Perfil de facturación"
              onBillingProfileChanged={BillingProfile =>
                handleChange("BillingProfile", BillingProfile)
              }
            />
          </Box>
        </Grid>
        <Grid item paddingTop={5} xs={12}>
          <MHData
            disabled
            billingProfile={
              placeForm.IsUsingDefaultBillingProfile
                ? defaultBillingProfile
                : placeForm?.BillingProfile
            }
            setModalOpen={setModalOpen}
          />
        </Grid>
        <Grid item paddingTop={5} xs={12}>
          <FormWithButtons
            buttonsAlignment="end"
            buttonsSpace={3}
            onCancel={() => navigate("/places")}
          />
        </Grid>
      </Grid>
    </form>
  );
}
export default PlacesRegister;
