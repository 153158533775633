import PropTypes from "prop-types";
import { Box, Radio } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@emotion/react";

export default function SquareRadioCheck(props) {
  return (
    <Radio
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      checkedIcon={<SquareRadioCheckedIcon checked />}
      icon={<SquareRadioCheckedIcon />}
    />
  );
}

function SquareRadioCheckedIcon({ checked }) {
  const theme = useTheme();
  const radioCheckColors = {
    border: {
      dark: "rgba(9, 25, 40, 1)",
    },
    background: {
      dark: "rgba(21, 39, 97, 1)",
      light: "rgba(199, 221, 240, 0.5)",
    },
  };

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        border: `2px solid ${radioCheckColors.border.dark}`,
        backgroundColor: checked
          ? radioCheckColors.background[theme.palette.mode]
          : theme.palette.baseColor.main,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {checked && (
        <CheckIcon
          sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
        />
      )}
    </Box>
  );
}

SquareRadioCheckedIcon.propTypes = {
  checked: PropTypes.bool,
};
SquareRadioCheckedIcon.defaultProps = {
  checked: false,
};
