import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  getAllCountries,
  GET_ALL_CANTONS,
  GET_ALL_DISTRICTS,
  GET_ALL_NEIGHBORHOODS,
  GET_ALL_OTHERS_CHARGES_DOCUMENT_TYPE,
  GET_ALL_PERSON_TYPES,
  GET_ALL_PROVINCES,
  GET_ALL_UNIT_OF_MEASURE,
  GET_USER_DOCUMENT_ID,
  PATCH_BILLING_PROFILE,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_SELL_CONDITIONS,
  GET_ALL_TAXES,
  GET_ALL_TARIFF,
} from "../graphql";
import { client_EB_api } from "../graphql/client";
import { useLocalStorage } from "../hooks";
import useCoinDetails from "../hooks/useCoinDetails";
import {
  addressInitialState,
  electronicBillInitialState,
  expenseFormInitialState,
  exportationBillInitialState,
  productInitialState,
  receiverInitialState,
  summaryExpensesInitialState,
  billConfigurationEmptyState,
} from "../InitialStates/ElectronicContext";

export const ElectronicContext = createContext(null);
function ElectronicProvider({ children }) {
  // Queries
  const { data: getAllPersonType } = useQuery(GET_ALL_PERSON_TYPES);
  const { data: getAllUnitOfMeasure } = useQuery(GET_ALL_UNIT_OF_MEASURE);
  const { data: getAllPaymentMethods } = useQuery(GET_ALL_PAYMENT_METHODS);
  const { data: getAllSellCondition } = useQuery(GET_ALL_SELL_CONDITIONS);
  const { data: taxesData } = useQuery(GET_ALL_TAXES);
  const { data: tariff } = useQuery(GET_ALL_TARIFF);
  const [isBilling, setIsBilling] = useState(false);
  const { data: getAllOthersChargesDocumentType } = useQuery(
    GET_ALL_OTHERS_CHARGES_DOCUMENT_TYPE,
  );
  const { data: dataCountries } = useQuery(getAllCountries);
  const [getUserByDocumentID, { data: userByDocumentId_Person }] =
    useLazyQuery(GET_USER_DOCUMENT_ID);

  const location = useLocation();

  // States
  const useCoinDetail = useCoinDetails();

  const [unitOfMeasurementList, setUnitOfMeasureList] = useState([
    { Name: "Unidad de medida", Id: 0 },
  ]);
  const [documentTYpeOthersList, setDocumentTYpeOthersList] = useState([
    { Name: "Tipo de documento", Id: 0 },
  ]);
  const [personTypeList, setPersonTypeList] = useState([
    { Name: "Sin Seleccionar", Id: 0 },
  ]);

  const [LineDetailPlace, setLineDetailPlace] = useState({ id: 0 });

  const [clientGroup, setClientGroup] = useState({ id: 0 });

  const [accountSelected, setAccountSelected] = useState({ id: 0 });

  const [electronicBill, setElectronicBill] = useState(
    electronicBillInitialState,
  );
  const [storedValue, saveOnStorage] = useLocalStorage("place", { id: 0 });
  const [storedAccountValue, saveAccountOnStorage] = useLocalStorage(
    "account",
    { id: 0 },
  );

  const [provinces, setProvinces] = useState([]);

  const [cantones, setCantons] = useState([{ Name: "Sin Seleccionar", Id: 0 }]);
  const [districts, setDistricts] = useState([
    { Name: "Sin Seleccionar", Id: 0 },
  ]);
  const [neighborhoods, setNeighborhoods] = useState([
    { Name: "Sin Seleccionar", Id: 0 },
  ]);
  const [scheduledBill, setScheduledBill] = useState(
    billConfigurationEmptyState,
  );
  // client selected
  const [receiver, setReceiver] = useState({ ...receiverInitialState });
  // state to search on BillingProfile
  const [profile, setProfile] = useState({ id: "" });
  // List of clients of user
  const [clientList, setClientList] = useState([]);

  const [product, setProduct] = useState(productInitialState);

  const [lineDetail, setLineDetail] = useState([]);

  const [address, setAddress] = useState(addressInitialState);
  const [lineOtherCharges, setLineOtherCharges] = useState([]);

  // Effects
  useEffect(() => {
    if (
      userByDocumentId_Person &&
      userByDocumentId_Person.userByDocumentId_Person
    ) {
      const { userByDocumentId_Person: user } = userByDocumentId_Person;

      dataCountries?.countries?.forEach(country => {
        if (country.PhoneCode_Country === user.PhoneCodCountry) {
          setReceiver(rev => ({
            ...rev,
            Receiver_PhoneCode: country.id,
          }));
        }
      });

      setReceiver(rev => ({
        ...rev,
        id: user.id,
        Receiver_Id: user.ID_Number,
        Receiver_Name: user.Name,
        Receiver_Email: user.Email,
        Receiver_PhoneNumber: user.PhoneNumber,
        Receiver_PersonType: user.MH_PersonType.id,
      }));
      setProfile({ id: user.id });

      const { MH_Neighborhood } = user;
      setAddress(prev => ({
        ...prev,
        Province: MH_Neighborhood.District.Canton.Province.Name,
        Canton: MH_Neighborhood.District.Canton.Name,
        District: MH_Neighborhood.District.Name,
        Neighborhood: MH_Neighborhood.Name,
        Other: user.OtherSigns,
      }));
    } else {
      setReceiver(prev => ({
        ...prev,
        ...receiverInitialState,
      }));

      setAddress(prev => ({
        ...prev,
        ...addressInitialState,
      }));

      setCantons([{ Name: "Sin Seleccionar", Id: 0 }]);
      setDistricts([{ Name: "Sin Seleccionar", Id: 0 }]);
      setNeighborhoods([{ Name: "Sin Seleccionar", Id: 0 }]);
    }
  }, [userByDocumentId_Person, dataCountries]);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    setLineDetailPlace(storedValue);
  }, [storedValue]);

  useEffect(() => {
    setAccountSelected(storedAccountValue);
  }, [storedAccountValue]);

  const getProvinces = async () => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_PROVINCES,
    });
    const _provinces = data?.provinces?.map(province => ({
      ...province,
      Name: province.Name,
      Id: province.id,
    }));
    _provinces.unshift({
      Name: "Seleccione",
      Id: 0,
    });
    if (_provinces && _provinces.length > 0) setProvinces(_provinces);
    setCantons([{ Name: "Sin Seleccionar", Id: 0 }]);
    setDistricts([{ Name: "Sin Seleccionar", Id: 0 }]);
    setNeighborhoods([{ Name: "Sin Seleccionar", Id: 0 }]);
  };

  useEffect(() => {
    let data = [];

    data = getAllPersonType?.personTypes?.map(personType => ({
      ...personType,
      Name: personType.Name_PersonType,
      Id: personType.id,
    }));
    setPersonTypeList(
      data && data.length > 0 ? data : [{ Name: "Sin Seleccionar", Id: 0 }],
    );
    data = [];
    data = getAllUnitOfMeasure?.unitsOfMeasurement?.map(unitOfMeasure => ({
      ...unitOfMeasure,
      Name: unitOfMeasure.Name_UnitOfMeasurement,
      Id: unitOfMeasure.id,
    }));
    setUnitOfMeasureList(
      data && data.length > 0 ? data : [{ Name: "Unidad de Medida", Id: 0 }],
    );
    data = [];
    data = getAllOthersChargesDocumentType?.DocumentType?.map(document => ({
      ...document,
      Name: document.Name,
      Id: document.id,
    }));
    setDocumentTYpeOthersList(
      data && data.length > 0 ? data : [{ Name: "Tipo Documento", Id: 0 }],
    );
  }, [getAllPersonType, getAllUnitOfMeasure, getAllOthersChargesDocumentType]);

  const handleChangeScheduledBill = data => {
    setScheduledBill(prev => ({ ...prev, ...data }));
  };

  const handleSetPlace = useCallback(
    place => {
      if (LineDetailPlace.id !== place.id) {
        setLineDetailPlace(place);
        saveOnStorage(place);
      }
    },
    [LineDetailPlace, saveOnStorage],
  );

  const handleSetAccount = useCallback(
    account => {
      setAccountSelected(account);
      saveAccountOnStorage(account);
    },
    [saveAccountOnStorage],
  );

  const handleChange = (name, value) => {
    setElectronicBill(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeElectronicBill = (name, value) => {
    setElectronicBill(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeDetail = useCallback(
    (name, value) => {
      let select;
      if (name === "Unit_Of_Measure") {
        select = unitOfMeasurementList.filter(unid => unid.id === value);
      }
      setProduct(prev => ({
        ...prev,
        [name]: select ? { Id: select[0].Id, Symbol: select[0].Symbol } : value,
      }));
    },
    [unitOfMeasurementList],
  );

  const handleClearProduct = () => {
    setProduct(productInitialState);
  };

  const handleChangeReceiver = (name, value) => {
    setReceiver(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeLineCharges = line => {
    setLineOtherCharges(prev => [...prev, line]);
  };

  const fetchCantonsByProvinceId = async FK_Province => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_CANTONS,
      variables: {
        FK_Province,
      },
    });
    return data?.cantons;
  };
  const fetchDistrictsByCantonId = async FK_Canton => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_DISTRICTS,
      variables: {
        FK_Canton,
      },
    });
    return data?.districts;
  };
  const fetchNeighborhoodsByDistrictId = async FK_District => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_NEIGHBORHOODS,
      variables: {
        FK_District,
      },
    });
    return data?.neighborhoods;
  };

  const fixUserLocationData = userLocationData => [
    { Name: "Sin Seleccionar", Id: -1 },
    ...(userLocationData?.map(({ Name, id }) => ({ Name, Id: id })) || []),
  ];

  const updateCantonsState = useCallback(async FK_Province => {
    const fetchedCantons = await fetchCantonsByProvinceId(FK_Province);
    setCantons(fixUserLocationData(fetchedCantons));
  }, []);

  const updateDistrictsState = useCallback(async FK_Canton => {
    const fetchedDistricts = await fetchDistrictsByCantonId(FK_Canton);
    setDistricts(fixUserLocationData(fetchedDistricts));
  }, []);

  const updateNeighborhoodsState = useCallback(async FK_District => {
    const fetchedNeighborhoods =
      await fetchNeighborhoodsByDistrictId(FK_District);
    setNeighborhoods(fixUserLocationData(fetchedNeighborhoods));
  }, []);

  const updateUserLocationState = useCallback(
    async (FK_Province, FK_Canton, FK_District) => {
      await updateCantonsState(FK_Province);
      await updateDistrictsState(FK_Canton);
      await updateNeighborhoodsState(FK_District);
    },
    [updateCantonsState, updateDistrictsState, updateNeighborhoodsState],
  );

  const handleChangeAddress = useCallback(
    (name, value) => {
      setAddress(prev => ({ ...prev, [name]: value }));
      if (name === "Province") {
        setProvinces(provinces.filter(prev => prev.Id !== 0));
        updateCantonsState(value);
        setDistricts([{ Name: "Sin Seleccionar", Id: 0 }]);
        setNeighborhoods([{ Name: "Sin Seleccionar", Id: 0 }]);
      } else if (name === "Canton") {
        updateDistrictsState(value);
      } else if (name === "District") {
        updateNeighborhoodsState(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provinces],
  );

  const resetReceiver = useCallback(() => {
    setReceiver({ ...receiverInitialState });
  }, []);

  const resetElectronicBill = useCallback(() => {
    setElectronicBill(electronicBillInitialState);
  }, []);

  const handleSearch = useCallback(
    async e => {
      if (profile.id !== null && profile.id !== "") {
        await getUserByDocumentID({
          variables: {
            DocumentId_Person: profile.id,
          },
        });
      }
    },
    [getUserByDocumentID, profile.id],
  );

  const patchBillingProfile = async billingProfile => {
    try {
      const response = await client_EB_api.mutate({
        mutation: PATCH_BILLING_PROFILE,
        variables: { billingProfile },
      });
      if (!response?.data?.BillingProfile) {
        throw new Error("Ocurrió una error durante la actualización");
      }
      return response.data.BillingProfile;
    } catch (error) {
      return error.message;
    }
  };

  const reverseBillingName = name => {
    const partsName = name.split(" ");
    let Name;
    let Lastname;
    if (partsName.length >= 3) {
      Lastname = partsName.splice(-2, 2).join(" ");
      Name = partsName.join(" ");
    } else {
      Lastname = partsName.splice(-1, 1).join(" ");
      Name = partsName.join(" ");
    }
    return { Name, Lastname };
  };

  const countries = useMemo(
    () => dataCountries?.countries || [],
    [dataCountries?.countries],
  );

  // this part is used for 'FEC's
  const [expenseForm, setExpenseForm] = useState({
    ...expenseFormInitialState,
  });

  const [summaryExpenses, setSummaryExpenses] = useState(
    summaryExpensesInitialState,
  );
  const [infoSummary, setInfoSummary] = useState([]);
  const [summaryId, setSummaryId] = useState(0);

  const [dates, setDates] = useState({
    startDate: {
      value: (() => {
        const startdate = new Date();

        return `${startdate.getFullYear()}-${
          startdate.getMonth() + 1 < 10 ? 0 : ""
        }${startdate.getMonth() + 1}-01`;
      })(),
      errors: "",
    },
    endDate: {
      value: (() => {
        const enddate = new Date();

        return `${enddate.getFullYear()}-${
          enddate.getMonth() + 1 < 10 ? 0 : ""
        }${enddate.getMonth() + 1}-${
          enddate.getDate() < 10 ? 0 : ""
        }${enddate.getDate()}`;
      })(),
      errors: "",
    },
  });

  useEffect(() => {
    if (
      !(
        location.pathname === "/electronic-bills" ||
        location.pathname === "/electronic-bills/client" ||
        location.pathname === "/buy-electronic-bills" ||
        location.pathname === "/electronic-bills/details" ||
        location.pathname === "/exportation-bill"
      )
    ) {
      setSummaryExpenses({ subTotal: 0, iva: 0, discount: 0, total: 0 });
      setExpenseForm({
        ...expenseFormInitialState,
      });
      setInfoSummary([]);
      setSummaryId(0);
      resetReceiver();
    }
  }, [location.pathname, resetReceiver]);

  const [exportationBasicInfo, setExportationBasicInfo] = useState(
    exportationBillInitialState,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetExportationBasicInfo = () => {
    setExportationBasicInfo(exportationBillInitialState);
  };

  const valueToReturn = useMemo(
    () => ({
      address,
      cantones,
      clientList,
      dates,
      districts,
      documentTYpeOthersList,
      electronicBill,
      handleChange,
      handleChangeAddress,
      handleChangeDetail,
      handleChangeElectronicBill,
      handleChangeLineCharges,
      handleChangeReceiver,
      setLineDetail,
      handleClearProduct,
      handleSearch,
      lineDetail,
      lineOtherCharges,
      neighborhoods,
      personTypeList,
      LineDetailPlace,
      product,
      profile,
      provinces,
      receiver,
      setAddress,
      setClientList,
      setDates,
      setElectronicBill,
      setLineOtherCharges,
      setLineDetailPlace: handleSetPlace,
      setProfile,
      setReceiver,
      unitOfMeasurementList,
      getProvinces,
      updateCantonsState,
      updateDistrictsState,
      updateNeighborhoodsState,
      patchBillingProfile,
      reverseBillingName,
      updateUserLocationState,
      countries,
      useCoinDetail,
      expenseForm,
      setExpenseForm,
      summaryExpenses,
      setSummaryExpenses,
      infoSummary,
      setInfoSummary,
      summaryId,
      setSummaryId,
      clientGroup,
      setClientGroup,
      getAllPaymentMethods,
      getAllSellCondition,
      isBilling,
      setIsBilling,
      resetReceiver,
      taxesData,
      tariff,
      getAllUnitOfMeasure,
      accountSelected,
      setAccountSelected: handleSetAccount,
      resetElectronicBill,
      scheduledBill,
      setScheduledBill,
      handleChangeScheduledBill,
      resetExportationBasicInfo,
      setExportationBasicInfo,
      exportationBasicInfo,
      fetchCantonsByProvinceId,
      fetchDistrictsByCantonId,
      fetchNeighborhoodsByDistrictId,
      fixUserLocationData,
      saveOnStorage,
    }),
    [
      address,
      cantones,
      clientList,
      dates,
      districts,
      documentTYpeOthersList,
      electronicBill,
      handleChangeAddress,
      handleChangeDetail,
      handleSearch,
      lineDetail,
      lineOtherCharges,
      neighborhoods,
      personTypeList,
      LineDetailPlace,
      product,
      profile,
      provinces,
      receiver,
      handleSetPlace,
      unitOfMeasurementList,
      updateCantonsState,
      updateDistrictsState,
      updateNeighborhoodsState,
      updateUserLocationState,
      countries,
      useCoinDetail,
      expenseForm,
      summaryExpenses,
      infoSummary,
      summaryId,
      clientGroup,
      getAllPaymentMethods,
      getAllSellCondition,
      isBilling,
      resetReceiver,
      taxesData,
      tariff,
      getAllUnitOfMeasure,
      accountSelected,
      handleSetAccount,
      resetElectronicBill,
      scheduledBill,
      exportationBasicInfo,
      saveOnStorage,
    ],
  );

  return (
    <ElectronicContext.Provider value={valueToReturn}>
      {children}
    </ElectronicContext.Provider>
  );
}

ElectronicProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useElectronic = () => useContext(ElectronicContext);

export default ElectronicProvider;
