import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

function DataStack({ data }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      {data.map(({ label, value }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Typography fontWeight={700} sx={{ width: "100px" }}>
            {label}:
          </Typography>
          <Typography sx={{ width: "75%" }}>{value}</Typography>
        </Box>
      ))}
    </Box>
  );
}

DataStack.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};

export default DataStack;
