import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// External components
import { Box, Grid, Button, Typography } from "@mui/material";

// Graphql
import { useQuery } from "@apollo/client";
import { GET_CREDIT_NOTES_CARD, GET_DEBIT_NOTES_CARD } from "../../graphql";
// Components
import { CreditDebitCard } from "../../components";
import LandingPage from "../LandingPage";
// Helpers
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";

function DebitNotes() {
  const navigate = useNavigate();
  const [activeNote, setActiveNote] = useState(false);
  const { id } = useParams();
  const { data: debitNotes } = useQuery(GET_DEBIT_NOTES_CARD, {
    variables: {
      whereParams: {
        comparisons: [["FK_ReferencedElectronicBill", "=", id]],
      },
    },
    fetchPolicy: "no-cache",
    context: {
      clientName: "apiEB",
    },
  });
  const { data: creditNotesData, loading: isLoading } = useQuery(
    GET_CREDIT_NOTES_CARD,
    {
      variables: {
        whereParams: {
          comparisons: [["FK_ReferencedElectronicBill", "=", id]],
        },
      },
      fetchPolicy: "no-cache",
      context: {
        clientName: "apiEB",
      },
    },
  );

  const creditNotesSortedAscendant = sortArrayItemsByDate(
    creditNotesData?.getCreditNotesNew,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );
  useEffect(() => {
    const active = creditNotesData?.getCreditNotesNew?.find(
      item => item?.DebitNote === null,
    );
    setActiveNote(active);
  }, [creditNotesData]);

  return (
    <Box className="content-flex-column">
      <Typography
        align="center"
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        Notas de crédito y debito
      </Typography>
      {isLoading && <LandingPage />}
      <br />

      <Grid container align="center" justifyContent="center" spacing={2}>
        {/* Left Grid for Crédito */}
        <Grid item sm={3} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align="center"
                typography="modeColor"
                variant="h5"
                width="100%"
              >
                Notas de crédito
                {` ${creditNotesData?.getCreditNotesNew?.length || 0}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {!activeNote && !isLoading && (
                <Button
                  className="btn-animate"
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/electronic-bills/${id}/CreditNote`)}
                >
                  Nueva nota de crédito
                </Button>
              )}
            </Grid>
            <Grid item sm={12} />
            <Grid item sm={12} />
            {creditNotesData?.getCreditNotesNew?.length > 0 && (
              <Grid container spacing={1}>
                {creditNotesSortedAscendant.map(note => (
                  <Grid key={note.id} item sx={{ marginBottom: 1 }} xs={12}>
                    <CreditDebitCard
                      actions={{
                        onClick: () =>
                          navigate(`/electronic-bills/${note.id}/CreditNote`),
                      }}
                      note={note}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Right Grid for Débito */}
        <Grid item sm={3} xs={12}>
          <Grid container spacing={2} sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
              <Typography typography="modeColor" variant="h5" width="100%">
                Notas de débito {debitNotes?.getDebitNotes?.length ?? "0"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {!activeNote && !isLoading && (
                <Button
                  className="btn-animate"
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/electronic-bills/${id}/DebitNote`)}
                >
                  Nueva nota de débito
                </Button>
              )}
            </Grid>
            <Grid item sm={12} />
            <Grid item sm={12} />
            {debitNotes?.getDebitNotes?.length > 0 && (
              <Grid container spacing={1}>
                {debitNotes?.getDebitNotes?.map(note => (
                  <Grid key={note.id} item sx={{ marginBottom: 1 }} xs={12}>
                    <CreditDebitCard
                      actions={{
                        onClick: () =>
                          navigate(`/electronic-bills/${note.id}/DebitNote`),
                      }}
                      note={note}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
DebitNotes.propTypes = {};
export default DebitNotes;
